import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DATA_PARENTS, empty } from '../../utils/constants';
import {
  actionSaveComponentDescriptionData,
  actionToggleComponentDescription,
} from '../../redux/support/action';
import { actionShowLibraryWidget } from '../../redux/currentPage/action';
import styles from '../../components/MainLayout/sideBarsWrapper.module.scss';
import PlaylistSideBarTabs from './PlaylistTabs/SideBarTabs';
import UPVLib from './MakerCommon/UiComponents/LibraryRightSideBar';
import CommentsRightSideBar from './MakerCommon/UiComponents/CommentsRightSideBar';
import SettingsRightSideBar from './MakerCommon/UiComponents/SettingsRightSideBar';

const cn = classNames.bind(styles);
let transitionTimer;
let transitionTimer2;

const PlaylistSideBarsWrapper = ({
  forUPV,
  currentPage,
  clickOutSideRefLib,
  isShowRightSideBarLib,
  setIsShowRightSideBarLib,
  currentRole,
  isPreventCloseMutableRef,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isShowSettings } = useSelector((state) => state.support);
  const { id: userId } = useSelector((state) => state.user);
  const { componentDescription } = useSelector((state) => state.support);
  // const uploads = useSelector(state => state.uploads.components);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const isLargeRightBar = useSelector(
    (state) => state.currentPage.isLargeRightBar,
  );
  const isPages = history.location.pathname === '/pages';
  const isChannels = history.location.pathname === '/channels';
  const isChannel = history.location.pathname.startsWith('/channel');
  const isUPV = history.location.pathname.startsWith('/universal');
  const isLibrary = history.location.pathname.startsWith('/content/');
  const isManyPlaylists = history.location.pathname === '/playlists';
  const isViewPlaylists = history.location.pathname.includes('/view_playlist');
  const isPlaylist = history.location.pathname.startsWith('/playlist/');
  const isEditPage = history.location.pathname.startsWith('/edit_page');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { isShowLibraryWidget, owner, sideBarType = '' } = currentPage;
  const isViewMode = isEditPage && owner?.id !== userId;

  // const isPage = history.location.pathname.startsWith('/libraryPage')
  //   || history.location.pathname.startsWith('/edit_page');
  const isShowLibraryTabs = (isPlaylist
      || isEditPage
      || history.location.pathname.includes('libraryPage')
      || history.location.pathname.includes('maker')
      || (history.location.pathname.includes('shared_playlist_co_edit')
        && !isViewMode))
    && !isManyPlaylists
    && !isViewPlaylists;

  const TRANSITION_DURATION = 350;
  const [transitionStart, setTransitionStart] = useState('init');
  const [lastActiveSideBars, setLastActiveSideBars] = useState({});
  // const [refLibOpened, isShowRightSideBarLib, setIsShowRightSideBarLib] = useConditionalClickOutside(
  //   isShowLibraryWidget,
  //   DATA_PARENTS.PlaylistBuilderLibrary, state => state.library.isOpenCreateComponent);

  const [isNoAnimationNeeded, setIsNoAnimationNeeded] = useState(false);

  useEffect(() => {
    transitionTimer = setTimeout(() => {
      setLastActiveSideBars({
        settings: isShowSettings,
        library: isShowRightSideBarLib,
      });
    }, TRANSITION_DURATION);
    return () => {
      clearTimeout(transitionTimer);
    };
  }, [isShowRightSideBarLib, isShowSettings]);

  useEffect(() => {
    if (!isShowSettings && isShowRightSideBarLib && lastActiveSideBars.settings) setIsNoAnimationNeeded(false);
    else if (
      isShowSettings
      && !isShowRightSideBarLib
      && lastActiveSideBars.settings
    ) setIsNoAnimationNeeded(false);
    else if (
      isShowSettings
      && !isShowRightSideBarLib
      && lastActiveSideBars.library
    ) setIsNoAnimationNeeded(false);
    else if (
      !isShowSettings
      && isShowRightSideBarLib
      && lastActiveSideBars.library
    ) setIsNoAnimationNeeded(false);
    else if (!isShowSettings && isShowRightSideBarLib) setIsNoAnimationNeeded(true);
    else if (isShowSettings && !isShowRightSideBarLib) setIsNoAnimationNeeded(true);
    else if (!isShowSettings && !isShowRightSideBarLib) setIsNoAnimationNeeded(true);
  }, [lastActiveSideBars]);

  useEffect(() => {
    if (transitionStart === 'init' && !isShowRightSideBarLib && !isShowSettings) return;
    setTransitionStart(true);
    transitionTimer2 = setTimeout(() => {
      setTransitionStart(false);
    }, TRANSITION_DURATION);
    return () => {
      clearTimeout(transitionTimer2);
    };
  }, [isShowRightSideBarLib, isShowSettings]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(actionToggleComponentDescription(false));
      dispatch(actionSaveComponentDescriptionData());
      dispatch(actionShowLibraryWidget(false));
    });
  }, [history.location.pathname]);

  useEffect(() => {
    setIsShowRightSideBarLib(isShowLibraryWidget);
  }, [isShowLibraryWidget]);

  useEffect(() => {
    // TODO NEED UPDAte
    // if (!isShowRightSideBarLib && metaId) {
    //   dispatch(actionToggleComponentDescription(false));
    //   dispatch(actionSaveComponentDescriptionData(null, null));
    // }
    if (!isShowRightSideBarLib && isShowLibraryWidget) {
      dispatch(actionShowLibraryWidget(isShowRightSideBarLib));
    }
  }, [isShowRightSideBarLib]);

  const setShowLibrary = (show) => {
    setIsShowRightSideBarLib(show);
  };

  return (
    <div className={cn('sideBars', { is_collapsed: !isShowRightSideBarLib })}>
      {!isLibrary
        && !isChannels
        && !isChannel
        && !isUPV
        && !isPages
        && !isManyPlaylists
        && !isViewPlaylists && (
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={cn('library_sideBar', {
              new_side_bar: true,
              expanded: isShowRightSideBarLib && isLargeRightBar,
              open:
                ((transitionStart !== 'init'
                  && !transitionStart
                  && isShowRightSideBarLib)
                  || (isNoAnimationNeeded && isShowRightSideBarLib))
                && sideBarType !== 'comments'
                && sideBarType !== 'settings',
              openLow:
                ((transitionStart !== 'init'
                  && !transitionStart
                  && isShowRightSideBarLib)
                  || (isNoAnimationNeeded && isShowRightSideBarLib))
                && (sideBarType === 'comments' || sideBarType === 'settings'),
              close:
                transitionStart !== 'init'
                && transitionStart
                && !isShowRightSideBarLib
                && isNoAnimationNeeded,
            })}
          >
            {isShowLibraryTabs && (
              <PlaylistSideBarTabs
                setIsShowFilter={setIsShowFilter}
                isShowFilter={isShowFilter}
                wrapperRef={clickOutSideRefLib}
                showLibrary={isShowRightSideBarLib}
                isEditPage={
                  history.location.pathname.includes('edit_page')
                  || history.location.pathname.includes('libraryPage')
                }
                forUPV={forUPV}
                currentRole={currentRole}
              />
            )}
            {isShowRightSideBarLib && (
              <div>
                {sideBarType === 'library' && (
                  <UPVLib
                    wrapperRef={clickOutSideRefLib}
                    close={() => setShowLibrary(false)}
                    currentPage={currentPage}
                    isPreventCloseMutableRef={isPreventCloseMutableRef}
                  />
                )}
                {sideBarType === 'comments' && (
                  <CommentsRightSideBar wrapperRef={clickOutSideRefLib} />
                )}
                {sideBarType === 'settings' && (
                  <SettingsRightSideBar
                    wrapperRef={clickOutSideRefLib}
                    currentPage={currentPage}
                    type="playlist"
                  />
                )}
              </div>
            )}
          </div>
      )}

      {/* {(isLibrary || isPages || isChannel) && ( */}
      {/*  <div */}
      {/*    ref={refLibOpened} */}
      {/*    className={cn('componentMetaData_sideBar', { */}
      {/*      open: !!metaId && isShowRightSideBarLib && componentDescription, */}
      {/*    })} */}
      {/*  > */}
      {/*    <ComponentDescription2023 */}
      {/*      setIsShowRightSideBarLib={setIsShowRightSideBarLib} */}
      {/*      isShowRightSideBarLib={isShowRightSideBarLib} */}
      {/*    /> */}
      {/*  </div> */}
      {/* )} */}
    </div>
  );
};

export default PlaylistSideBarsWrapper;
