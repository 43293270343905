import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import { DATA_PARENTS, empty } from '../../../../../utils/constants';
import Search from './Search';
import BtnGroup from './BTNGroup';
import TabGroup from './TabGroup';
import { actionCreator } from '../../../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../../../redux/content/contentTypes';
import { sortedFunctions } from '../../../../../utils/dateConvert';
import Content from './Content';
import { FilterConsts } from './enums';
import { parseItemsForRightSideBar } from './helper';
import { LibRightSideBarWrapper } from './styled';
import { actionSwitchContentSortType } from '../../../../../redux/content/actions';

const UPVLib = ({
  close,
  wrapperRef,
  currentPage,
  dataP = DATA_PARENTS.PlaylistBuilderLibrary,
  isPage = false,
  isPreventCloseMutableRef,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const arraySortedKey = Object.keys(sortedFunctions);
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const contentData = useSelector((state) => state.content?.contentData);

  const [searchString, setSearchString] = useState('');
  const [needToDelete, setNeedToDelete] = useState(false);
  const [scrollDelta, setScrollDelta] = useState(0);
  const [selector, setSelector] = useState('');
  const [sortType, setSort] = useState(arraySortedKey[2]);
  const [selectorType, setSelectorType] = useState('all');
  const [counterSelectedPage, setCounterSelectedPage] = useState(0);
  const [items, setItems] = useState([]);
  const [chosenSmartView, setChosenSmartView] = useState({});
  const [chosenTag, setChosenTag] = useState({});
  const [dynamicTab, setDynamicTab] = useState('');
  const [isUsedMode, setIsUsedMode] = useState(false);
  const { tags } = useSelector((state) => state.user);

  const resetDynamicTab = () => {
    setDynamicTab('');
    setChosenSmartView({});
    setChosenTag({});
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: 'library',
          selectorType: 'all',
          sortType,
          isNeedUpdate: true,
        },
        'LibraryNew UPVLib 1',
      ),
    );
  };
  useEffect(() => {
    if (sortType === 'recent') {
      return;
    }
    sortType && dispatch(actionSwitchContentSortType(sortType));
    if (selectorType === 'used') {
      setIsUsedMode(true);
    } else {
      setIsUsedMode(false);
      const exSelector = searchString ? 'dynamicCollectionNew' : selector;
      const exSelectorType = searchString
        ? { libFilter: {}, libFilterSearch: searchString }
        : selectorType;
      if (searchString && FilterConsts[selector.toLowerCase()]) {
        exSelectorType.libFilter.fileType = FilterConsts[selector.toLowerCase()];
      }
      dispatch(
        actionCreator(
          ContentActionType.startUpload,
          {
            activeNavSlider: exSelector,
            selectorType: exSelectorType,
            sortType,
            isNeedUpdate: true,
          },
          'LibraryNew UPVLib 2',
        ),
      );
    }
  }, [sortType, selectorType, selector, searchString]);

  useEffect(() => {
    setCounterSelectedPage(
      Object.values(selectedPage || {}).reduce(
        (acc, i) => acc + (typeof i === 'boolean' ? 1 : Object.values(i || {}).length),
        0,
      ),
    );
  }, [selectedPage]);

  useEffect(() => {
    if (isUsedMode) {
      parseItemsForRightSideBar(
        isPage ? currentPage.blocks : currentPage.linkPages,
        selector,
        setItems,
        isPage,
        contentData,
      );
    } else {
      setItems(
        isPage
          ? Object.values(contentData)
          : Object.values(contentData).filter((it) => it.type !== 'component'),
      );
    }
  }, [contentData, isUsedMode]);

  useEffect(() => {
    setIsUsedMode(false);
    setSelectorType('all');
  }, [selector]);

  useEffect(() => {
    setChosenTag({});
    if (chosenSmartView.id) setDynamicTab(chosenSmartView.name || t('unnamedSmartviewT'));
  }, [chosenSmartView.id]);

  useEffect(() => {
    setChosenSmartView({});
    if (chosenTag.id) setDynamicTab(chosenTag.title || t('defaultTagNameT'));
  }, [chosenTag.id]);

  useEffect(() => {
    if (chosenTag.id && tags[chosenTag.id]) {
      setDynamicTab(tags[chosenTag.id]?.title || t('defaultTagNameT'));
    } else if (chosenTag.id) {
      setChosenSmartView({});
    }
  }, [tags]);

  return (
    <LibRightSideBarWrapper
      wrapperRef={wrapperRef}
      data-parent={dataP}
      isExpanded={currentPage.isLargeRightBar}
    >
      <Header close={close} dataP={dataP} isExpanded={currentPage.isLargeRightBar} />
      <Search
        dataP={dataP}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <BtnGroup
        dataP={dataP}
        setSelector={setSelector}
        selector={selector}
        sortType={sortType}
        setSort={setSort}
        setChosenSmartView={setChosenSmartView}
        setChosenTag={setChosenTag}
        dynamicTab={dynamicTab}
        chosenTag={tags[chosenTag?.id] || empty}
        resetDynamicTab={resetDynamicTab}
        isPage={isPage}
      />
      {!dynamicTab && (
        <TabGroup
          dataP={dataP}
          selector={selector}
          setSelectorType={setSelectorType}
        />
      )}
      <Content
        chosenSmartView={chosenSmartView}
        chosenTag={tags[chosenTag?.id] || empty}
        selectorType={selectorType}
        counterSelectedPage={counterSelectedPage}
        scrollDelta={scrollDelta}
        needToDelete={needToDelete}
        items={items}
        activeNavSlider={selector}
        sortType={sortType}
        searchString={searchString}
        isUsedMode={isUsedMode}
        setNeedToDelete={setNeedToDelete}
        dataP={dataP}
        isPreventCloseMutableRef={isPreventCloseMutableRef}
      />
    </LibRightSideBarWrapper>
  );
};

export default UPVLib;
