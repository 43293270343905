import styled from 'styled-components/macro';

export const InputArea = styled.textarea`
  height: auto;
  display: block;
  overflow: hidden;
  resize: none;
  border: none;
  background: none;
  margin-top: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  line-height: 38px;
  color: #343434;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  letter-spacing: 0.75px;
  padding: 1px;
  padding-left: 0;
  &::placeholder {
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    line-height: 38px;
    color: #939393;
`;
