import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;

  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;
export const PreviewMainPreviewContentWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewContentWrapper = styled.div`
  max-height: calc(100vh - 200px);
  width: max-content;
  overflow: hidden;
  margin: auto;

  ${({ isPDF }) => (isPDF ? '' : '  max-width: 1200px;')}
  ${({ isImage }) => (isImage ? 'max-width: none;' : '')}
  ${({ isVideo }) => (isVideo ? 'width: 100%;' : '')}
  svg path {
    stroke: #484343;
  }
`;
export const ModalClickoutsideHandler = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
`;
export const PreviewLeftArrowWrapper = styled.div`
  position: absolute;
  left: 40px;
  top: 48%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: rgba(218, 217, 218, 0.8);
  border-radius: 50%;

  svg {
    margin-top: 30%;
    margin-left: 31%;
  }

  &:hover {
    background: rgba(218, 217, 218);
  }

  ${({ isDisabled }) => (isDisabled ? 'display:none;' : '')}
`;

export const PreviewRightArrowWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 48%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: rgba(218, 217, 218, 0.8);
  border-radius: 50%;

  svg {
    margin-top: 30%;
    margin-left: 40%;
    transform: rotate(-180deg);
  }

  &:hover {
    background: rgba(218, 217, 218);
  }

  ${({ isDisabled }) => (isDisabled ? 'display:none;' : '')}
`;

export const PreviewComponentWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  height: calc(100vh - 200px);
  background: white;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ${({ isPage }) => (isPage ? 'min-width: 700px;' : '')}
  ${({ customStyle }) => (customStyle ?? '')}
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  > div:first-child {
    margin: auto;
    height: auto;
    //width: unset;
  }
   
  ${({ transformed }) => (transformed ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    transition: top 0.2s;
  ` : `
    transform: none;
    max-height: none;
    transition: top 0.2s;
  `)}
`;

export const MinorCardWrapper = styled.div`
  width: 90px;
  min-width: 90px;
  height: 90px;
  border: 4px solid rgba(228, 227, 228, 0.7);
  background: #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 18px;
  ${({ active }) => (active ? 'border: 4px solid #FFC72C;' : '')}
  cursor: pointer;
  ${({ forFallback }) => (forFallback ? 'background:white;' : '')}
  ${({ isLibDescription }) => (isLibDescription
    ? `
  width: 170px;
  height: 170px;
    padding: 0;
    border: 1px #ECE2E2 solid;
    background: transparent;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 64px;
  `
    : '')}

`;

export const MinorCardImgWrapper = styled.img`
  width: 86px;
  height: 86px;
  object-fit: contain;
  overflow: hidden;
  ${({ isLibDescription }) => (isLibDescription
    ? `
  width:170px;
  height:170px;
  `
    : '')}
`;

export const MinorCardTextWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  overflow: hidden;
  padding: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  height: 78px;
  -webkit-box-orient: vertical;
`;

export const MinorCardPageWrapper = styled.div`
  visibility: hidden;
  animation: MiniPage_css__fk-MH 0s 0.8s forwards;
  overflow: hidden;
  height: 86px;
  width: 86px;
  background: white;

  div {
    //width: 100%;
    //height:100%;
    //transform: scale(0.24) translate(-153%, -154%);
  }

  ${({ isLibDescription }) => (isLibDescription
    ? `
  width:170px;
  height: 170px;
  `
    : '')}
`;

export const MinorCardFallbackContainer = styled.div`
  position: relative;
  width: 86px;
  height: 86px;
  border-radius: 4px;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
    color: #484343;
    font-size: 6px;
  }

  ${({ isLink }) => (isLink
    ? `
    pointer-events: none;
    width: 86px;
    height: 93px;
    overflow: hidden;
    background: #FFFFFF;
    position: inherit;
    svg{
    position: relative;
    top: 14px;
    left: 13px;
}
iframe{
    border:none;
    position:relative;
    width: 850px;
    height: 920px;
    transform: scale(0.1) translate(-451%,-451%);
}
    }
    
  `
    : '')}
  ${({ isLibDescription }) => (isLibDescription
    ? `
       width:170px;
       height:170px;
       iframe{
              width:170px;
       height:170px;
       }
    `
    : '')}
  ${({ isLink, isLibDescription }) => (isLibDescription && isLink
    ? `

    iframe{
     scale: 0.2;
     transform: translate(-200%, -199%);
     width: 1920px;
     height: 1080px;
    }
  `
    : '')}
`;

export const MinorCardComponentWrapper = styled.div`
  transform: scale(0.1869) translate(-210%, -213%);
  width: 640px;
  height: 1260px;
  overflow: hidden;
  position: relative;
  top: -11px;
  left: -10px;
  ${({ isLibDescription }) => (isLibDescription
    ? `
    transform: scale(0.234) translate(-153%,-159%);
  `
    : '')}
`;

export const HeaderControlsSVGWrapper = styled.div`
  cursor: pointer;
  visibility: visible;

  svg path {
    stroke: #484343;
  }

  ${({ isLocked, isLock }) => (!isLocked && isLock ? 'visibility:hidden;' : '')}
`;

export const HeaderControlsWrapper = styled.div`
  width: fit-content;
  gap: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 21px;
  right: 40px;
`;

export const HeaderCloseWrapper = styled.div`
  width: fit-content;
  gap: 40px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 21px;
  right: 40px;
`;

export const MainLightGalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const HeaderWrapper = styled.div`
  height: 66px;
  width: 100%;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding-top: 19px;
  color: #484343;
`;

export const HeaderCounterWrapper = styled.div`
  position: absolute;
  left: 40px;
  top: 19px;
`;

export const HeaderTitleWrapper = styled.div`
  width: 80%;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


export const GalleryBottomWrapper = styled.div`
  height: 134px;
  width: 100%;
  position: relative;
  bottom: 0;
  padding-top: 24px;

  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const GalleryWrapper = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  //width:max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const MinorFailedVideoWrapper = styled.div`
  padding-top: 56px;
  padding-left: 65px;

  svg {
    width: 44px;
    height: 49px;

    path {
      stroke: #ece2e2;
    }
  }
`;
