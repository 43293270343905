import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { keyframes, css } from 'styled-components';
import PreviewLightGallery from '../CustomLightGallery';
import {
  DEFAULT_DURATION,
  empty,
  emptyArr,
  SHARED_PLAYLIST_URL,
} from '../../utils/constants';
import { actionCloseModal } from '../../redux/user/action';
import { smartFileItemTypeCheck } from '../../shared/smartFile/constant';

const ModalWrapper = styled.div`
  z-index: 10104;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const convertDurationToLength = (duration) => {
  return duration * 3;
};

const appearAnimation = (percentage) => keyframes`
  0% {
    transform: scale(${percentage});
  }
  100% {
    transform: scale(1);
  }
`;

const reverseAppearAnimation = (percentage) => keyframes`
  0% {
    transform: scale(1);   
  }
  100% {
    transform: scale(${percentage});
  }
`;

const openBackGrownd = keyframes`
  0% {
      background-color: rgba(246, 246, 245, 0.1);
  }
  100% {
      background-color: rgba(246, 246, 245, 0.9);
  }
`;
const openOpacity = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;
const ExpandingContainerWrap = styled.div`
  z-index: 117;
  animation: ${openBackGrownd} 0.6s linear;
  
  ${({ isSmaller }) => (
    isSmaller
      ? 'background-color: rgba(246, 246, 245, 0.1);'
      : 'background-color: rgba(246, 246, 245, 1);')}
  transition: all 0.7s cubic-bezier(0.25, 0, 0, 1.01);
  
  position: fixed;
  .fade_menu {
      transition: opacity 0.7s cubic-bezier(0.25, 0, 0, 1.01);
      animation: ${openOpacity} 0.6s linear;
      z-index: 1000;
      ${({ isSmaller }) => (isSmaller ? 'opacity: 0' : '')}
  }
  
  .expanded_content {
    position: fixed;
    width: ${({ isExpanded, width }) => ((isExpanded) ? '100vw' : `${width || 0}px`)};
    height: ${({ isExpanded, height }) => (
    (isExpanded) ? 'calc(-100px + 100vh)' : `${height || 0}px`)};
      
    left: ${({ isExpanded, left }) => ((isExpanded) ? '0' : `${left || 0}px`)};
    top: ${({ isExpanded, top }) => ((isExpanded) ? '0' : `${top || 0}px`)};
     
    transition: all 0.7s cubic-bezier(0.25, 0, 0, 1.01);
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        zoom: 1;
        
      ${({ isSmaller, percentage, preventResize, isExpanded }) => (
    (preventResize && !isExpanded) ? ''
      : isSmaller
        ? css`animation: ${reverseAppearAnimation(percentage)} 0.7s cubic-bezier(0.42, 0, 0, 1.01);`
        : css`animation: ${appearAnimation(percentage)} 0.7s cubic-bezier(0.42, 0, 0, 1.01);`)})}
  )}
      transform-origin: center center; 
    }
`;

const UPVpreview = () => {
  const history = useHistory();
  const isMaker = history.location.pathname.startsWith('/maker/')
    || history.location.pathname.startsWith('/shared_playlist/')
    || history.location.pathname.startsWith(`/${SHARED_PLAYLIST_URL}`);
  const [rawPages, setRawPages] = useState([]);
  const dispatch = useDispatch();

  const { dataPayload } = useSelector((state) => state?.user);
  const { mode, index, elemPosition, isNeedZoom, linkPages } = dataPayload || empty;
  const { top, width, left, height } = elemPosition ?? {};
  const [calcFilteredIndex, setCalcFilteredIndex] = useState(index);
  const linkPage1 = useSelector((state) => state.content?.contentIds);
  let linkPagesEx;
  if (mode === 'LIB') {
    linkPagesEx = useSelector((state) => state.content?.contentData);
  } else if (mode === 'inbox') {
    linkPagesEx = useSelector((state) => state.inbox?.pages);
  } else {
    linkPagesEx = useSelector(
      (state) => state.currentPage?.linkPages || emptyArr,
    );
  }
  if (linkPages) { linkPagesEx = linkPages; }
  const [isExpanded, setIsExpanded] = useState(false);
  const [preventResize, setPreventResize] = useState(false);
  const [isSmaller, setIsSmaller] = useState(false);
  const [percentage, setPercentage] = useState(1);

  useEffect(() => {
    if (isMaker && mode !== 'LIB') {
      let defaultIndex = 0;
      const filteredLinkPage = linkPagesEx.map((item) => {
        const isHasIndex = !(smartFileItemTypeCheck.isTitle[item.type] || smartFileItemTypeCheck.isText[item.type]);
        if (isHasIndex) {
          defaultIndex++;
        }
        return {
          ...item,
          length: convertDurationToLength(item.duration ?? 25),
          duration: item?.duration ?? DEFAULT_DURATION,
          customIndex: isHasIndex ? defaultIndex : null,
        };
      }).filter((i) => !!i.type);
      filteredLinkPage.forEach((item, indexFilteredLinkPage) => {
        if (dataPayload.id === item.id) {
          setCalcFilteredIndex(indexFilteredLinkPage);
        }
      });
      setRawPages(filteredLinkPage);
    } else if (mode === 'inbox') {
      const filteredLinkPage = Object.values(linkPagesEx)
        .map((item, elemIndex) => ({
          ...item,
          customIndex: !(smartFileItemTypeCheck.isTitle[item.type] || smartFileItemTypeCheck.isText[item.type])
            ? elemIndex + 1
            : null,
        }));
      filteredLinkPage.forEach((item, indexFilteredLinkPage) => {
        if (dataPayload.id === item.id) {
          setCalcFilteredIndex(indexFilteredLinkPage);
        }
      });
      setRawPages(filteredLinkPage);
    }
  }, [linkPage1?.length, linkPagesEx]);

  useEffect(() => {
    if (mode === 'LIB') {
      setRawPages(
        linkPage1.map((id, index) => {
          if (dataPayload.id === id) {
            setCalcFilteredIndex(index);
          }
          return {
            ...linkPagesEx[id],
            length: convertDurationToLength(linkPagesEx[id].duration ?? 25),
            duration: linkPagesEx[id]?.duration ?? DEFAULT_DURATION,
            customIndex: index + 1,
          };
        }),
      );
    }
  }, [linkPage1?.length, linkPagesEx]);


  useEffect(() => {
    if (isNeedZoom) {
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const calcPercentageHeight = (height + 40) / screenHeight;
      // enable if necessary
      // const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // const calcPercentageWidth = Math.max(((width + 40) / screenWidth), 1);
      // const calcPercentage = Math.max(calcPercentageHeight, calcPercentageWidth);

      setPercentage(calcPercentageHeight);
    }
    const timeoutId = setTimeout(() => {
      setIsExpanded(true);
    }, 100);
    setTimeout(() => {
      setPreventResize(true);
    }, 700);
    return () => clearTimeout(timeoutId);
  }, []);

  const close = useCallback(() => {
    setPreventResize(false);
    setIsExpanded(false);
    setIsSmaller(true);
    setTimeout(() => dispatch(actionCloseModal()), 500);
  }, []);

  return (
    <ExpandingContainerWrap
      preventResize={preventResize}
      percentage={percentage}
      height={height}
      top={top}
      width={width}
      left={left}
      isSmaller={isSmaller}
      isExpanded={isExpanded}
    >
      <ModalWrapper>

        <PreviewLightGallery
          close={close}
          length={rawPages.length}
          items={rawPages}
          index={calcFilteredIndex}
          isForLib={mode === 'LIB' || mode === 'inbox'}
          isInbox={mode === 'inbox'}
          isMaker={isMaker}
          setPreventResize={setPreventResize}
        />
      </ModalWrapper>

    </ExpandingContainerWrap>
  );
};

export default UPVpreview;
