import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Favorite20Svg } from '../../images/2023/svg/small/upv/favorite_22_22.svg';
import { ReactComponent as DownloadSvg } from '../../images/2023/svg/upv/folder-down.svg';
import {
  actionShowMessage,
} from '../../redux/support/action';
import { empty, MessageType, Group, emptyArr, openModalType, DATA_PARENTS } from '../../utils/constants';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import { ReactComponent as More20Svg } from '../../images/2023/svg/upv/ellipsis-vertical.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import SupportAction from '../../redux/support/types';
import { actionOpenAIModal, actionOpenModal } from '../../redux/user/action';
import EditPlaylist from '../../redux/playlists/types';
import ThreeDotsDropDownMaker from './ThreeDotsDropDownForMaker';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import SwitchModeAndPublishBlock from './SwitchModeAndPublishBlock';
import { actionAddPayloadUnifyHistory } from '../../redux/history/actions';
import { calcIfWholeSmartfileDownloadable } from '../../utils/helpers';

import ProgressBar from '../../components/ViewersProgressBars';
import { progressBarType } from '../../components/ViewersProgressBars/enum';
import Tooltip from '../../shared/Tooltips/Tooltip';
import {
  ButtonContainer,
  PlaylistControlsShell,
  PlaylistControlsShellWrapper,
  ThreeDotsDropDownUPVWrapper,
} from './styled';
import { actionUnPublishSPlaylistS } from '../../redux/playlists/action';
import { GreyItemsSplitter } from './components/Info/styled';

const Controls = ({
  id,
  title,
  socketId,
  isOwner,
  isForSharedToWeb,
  refForScroll,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    'miniPage',
  );
  const { requestSpinner } = useSelector((state) => state.support);
  const page = useSelector((reduxState) => reduxState.currentPage);

  const {
    shareState: { isShareToWeb, usersToSharing = emptyArr },
    wrapperId, isFinished, linkPages, isFavorite,
    singleUserShareState, currentRole,
    isPublish, inputs, ...currentPage
  } = useSelector((reduxState) => reduxState.currentPage || empty);

  const {
    id: sharedToWebPlaylistID,
    type,
    channelId = null,
  } = useParams();

  const threeDotsRef = useRef(null);

  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(true);
  const [isCanManyRemix, setIsCanManyRemix] = useState(true);
  const [isCanManyDownload, setIsCanManyDownload] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );
  const shouldDisplayProgress = !isOwner && !isForSharedToWeb;
  const isChannels = !!channelId;
  const isButtonsBlocked = !!requestSpinner && requestSpinner !== 'init' && requestSpinner === id;
  const isHasShare = !!Object.values(singleUserShareState).length
    || !!usersToSharing.length || isShareToWeb || isPublish;

  useEffect(() => {
    setIsEmpty(
      linkPages
        && !linkPages.filter((i) => i.type && i.type !== 'upload').length,
    );
    setIsCanManyRemix(
      linkPages && !!linkPages.filter((i) => i.type && !i.isRemixLocked).length,
    );
    setIsCanManyDownload(calcIfWholeSmartfileDownloadable(linkPages));
  }, [linkPages]);

  useEffect(() => {
    let text = isHasShare ? t('unshareUpT') : t('shareUpT');
    if (
      !!requestSpinner
      && requestSpinner !== 'init'
      && requestSpinner === id
    ) {
      text = t('sharingUpT');
    }
    setButtonText(text);
  }, [requestSpinner, isHasShare]);


  const unshare = useCallback(() => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('unshareUpT'),
        subject: t('unshareThisSmartFileQuestionT'),
        description: t('unshareDescriptionT'),
        confirm: () => {
          dispatch(actionUnPublishSPlaylistS(id, wrapperId));
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileUnsharedT'),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('unshareUpT'),
        type: 'unshare',
      }),
    );
  }, [dispatch, id, wrapperId]);

  const toggleIsMoreOpened = () => setIsActive(!isActive);

  const newGoToPlaylist = (event) => {
    event.stopPropagation();
    dispatch(actionPageWillDownload());
    const isShared = currentPage.status === 'shared';
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }
    if (isShared) {
      history.push(`/player/shared/${currentPage.id}/1`);
      return;
    }
    const firstChannelId = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';

    history.push(`/player/${firstChannelId}/${currentPage.id}/1`);
  };

  const togglePlaylistFavorite = (e) => {
    e.stopPropagation();
    dispatch(
      actionCreator(EditPlaylist.ToggleFavorite, {
        id,
        isFavorite: !isFavorite,
        wrapperId: currentPage.wrapperId,
      }),
    );
    if (!isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PlaylistToFavorite,
          itemName: title || t('defaultSmartFileTitleT'),
        }),
      );
    }
  };

  const showViewersProgressModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const downloadItemClickHandler = useCallback((e, filesArray, isLinkAdded, smartfileLink) => {
    e.stopPropagation();
    if (!linkPages?.length || !isCanManyDownload) {
      return;
    }
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadWholePlaylist,
        itemName: 'playlist',
        mode: Group.processing,
        id: currentPage.id,
      }),
    );
    axiosAbortarium.generateNew(currentPage.id);
    dispatch(
      actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
        playlistId: currentPage.id,
        playlistTitle: currentPage.title,
        filesArray,
        isLinkAdded,
        smartfileLink,
      }),
    );
    setIsActive(false);
  }, [linkPages?.length, isCanManyDownload, currentPage.id, currentPage.title]);

  const handleDownload = () => {
    if (!!linkPages?.length && isCanManyDownload) {
      dispatch(actionOpenModal(openModalType.DownloadPlaylistModal, { download: downloadItemClickHandler }));
    }
  };
  useEffect(() => {
    const makerRef = refForScroll.current;
    const closeModal = () => {
      setIsActive(false);
    };
    if (makerRef && isActive) {
      makerRef.addEventListener('scroll', closeModal);
    }
    return () => {
      if (makerRef) {
        makerRef.removeEventListener('scroll', closeModal);
      }
    };
  }, [refForScroll, setIsActive, isActive]);

  const ThreeDotsWrapper = () => {
    const position = threeDotsRef.current?.getBoundingClientRect();
    const top = position?.top + 30;
    const left = position?.left;
    return (
      <ThreeDotsDropDownMaker
        isMaker
        show={isActive}
        refMenu={refMenu}
        isActive={isActive}
        setIsActive={setIsActive}
        currentRole={currentRole}
        playlistId={id}
        isContentEditable
        socketId={socketId}
        isOwner={isOwner}
        isWholePlaylistOperation
        item={null}
        isEmpty={isEmpty}
        goToItem={(e) => {
          e.stopPropagation();
          dispatch(actionPageWillDownload());
          history.push(`/maker/${id}/edit`);
        }}
        handleDownload={handleDownload}
        currentPage={page}
        play={newGoToPlaylist}
        top={top}
        left={left}
      />
    );
  };
  return (
    <PlaylistControlsShellWrapper>
      <PlaylistControlsShell>
        <GreyItemsSplitter />
        {!isForSharedToWeb && (
          <ButtonContainer
            onClick={togglePlaylistFavorite}
            isFavorite={isFavorite}
            className="div_ripper"
          >
            <Tooltip
              text={isFavorite ? t('removeFromFavoritesT') : t('addToFavoritesT')}
              direction="down"
            >
              <Favorite20Svg />
            </Tooltip>
          </ButtonContainer>
        )}
        {isOwner && (
          <ButtonContainer
            isDisabled={!linkPages?.length || !isCanManyDownload}
            onClick={handleDownload}
            className="div_ripper"
          >
            {!linkPages?.length || !isCanManyDownload ? (
              <DownloadSvg />
            ) : (
              <Tooltip
                text={t('downloadThisSmartFileT')}
                direction="down"
              >
                <DownloadSvg />
              </Tooltip>
            )}
          </ButtonContainer>
        )}
        <ThreeDotsDropDownUPVWrapper>
          <ButtonContainer
            className="div_ripper three_dots"
            onClick={toggleIsMoreOpened}
            isActive={isActive}
            pL={-1}
            ref={threeDotsRef}
            data-parent={DATA_PARENTS.checkItem}
          >
            <Tooltip
              text={t('moreOptionsT')}
              direction="down"
            >
              <More20Svg data-parent={DATA_PARENTS.checkItem} />
            </Tooltip>
          </ButtonContainer>
          {createPortal(ThreeDotsWrapper(), document.body)}
        </ThreeDotsDropDownUPVWrapper>
      </PlaylistControlsShell>
    </PlaylistControlsShellWrapper>
  );
};

export default Controls;
