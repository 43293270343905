import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Playlist from '../../redux/playlists/types';
import { getItemName } from '../../utils/helpers';
import { ReactComponent as DuplicateSvg } from '../../images/icons/duplicate_no_plus.svg';
import { ReactComponent as DeleteSvg } from '../../images/icons/delete_red_straight.svg';
import { ReactComponent as DownloadSvg } from '../../images/2023/svg/upv/folder-down.svg';
import { actionShowMessage } from '../../redux/support/action';
import {
  DATA_PARENTS,
  Group,
  ITEM,
  MessageType,
  openModalType,
  PLAYLIST_TYPES_REVERT,
  stopPropagation,
} from '../../utils/constants';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import { actionOpenModal } from '../../redux/user/action';
import { ServiceUser } from '../../redux/user/types';
import { CHANNEL_PERMISSIONS, isRoleInPlaylist } from '../../utils/permissions';
import { Channels } from '../../redux/channels/types';
import {
  Divider,
  OptionsWrapper,
} from '../../components/MiniPlaylist/NewPlaylist2023/styled';
import { THREE_DOTS_DD_OPTIONS_UPV_TABLE } from '../../components/MiniPlaylist/NewPlaylist2023/helpers/enums';
import { actionCreator } from '../../shared/redux/actionHelper';
import {
  actionRemoveLinkPageFromPlaylist,
} from '../../redux/currentPage/action';
import { ThreeDotsDropWrapperMaker } from './styled';
import { actionDuplicatePlaylist } from '../../redux/playlists/action';

// separated to keep codebase cleaner then now. is used in 5 places for new 2023
// maker - list view, card view, table of content, whole playlist controls
// recipient - ThreeDotsDropDownUPV
const ThreeDotsDropDownMaker = ({
  isMaker,
  show,
  currentPage,
  refMenu,
  isWholePlaylistOperation,
  isTOC,
  positionDown,
  upvCard,
  currentRole,
  isEmpty,
  isPlaceholder,
  upvExpand,
  left,
  top,
  ...props
}) => {
  const { isViewer: exViewer, isOwner, isCoEdit: exCoEdit } = isRoleInPlaylist;
  const {
    type: typePlaylist,
    place = 'draft',
    channelID = null,
  } = useParams();
  const { t } = useTranslation();
  const channels = useSelector((state) => state.channels.myChannels);
  const dispatch = useDispatch();
  const history = useHistory();
  const userID = useSelector((state) => state.user.id);
  const isPlaylistOwner = userID === currentPage?.owner?.id;
  const isPublished = currentPage?.status === 'publish';
  const isCoEdit = exCoEdit[currentRole];
  const isViewer = exViewer[currentRole];
  const itemOwner = userID === props?.item?.owner?.id;
  const isLocked = props?.item?.isRemixLocked;
  const isCanEdit = isCoEdit || itemOwner || isPlaylistOwner;
  const isCanRemix = itemOwner || !isLocked;
  const canUnsubscribe = typePlaylist === 'shared' && isWholePlaylistOperation;
  const isCanDelete = !isViewer && !isWholePlaylistOperation || isOwner[currentRole];

  const roleInChannel = channels[channelID]?.role;
  const isChannelAdmin = roleInChannel === 'admin';
  const isChannelCoAdmin = roleInChannel === 'co_admin';
  const roleOwnerPlaylist = currentPage?.owner?.roleInChannel;

  const isHasMoreRoleFromOwnerPlaylist = CHANNEL_PERMISSIONS[roleInChannel]?.whichContentCanRemove[
    roleOwnerPlaylist
  ];
  const isCanRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && (isPlaylistOwner || isChannelAdmin || isHasMoreRoleFromOwnerPlaylist);
  const isShowNotActiveRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && !isCanRemoveFromChannel
    && isChannelCoAdmin;

  const libraryComponentId = props.item?.libraryComponent?.id
    || props.item?.textComponent?.id
    || props.item?.id;
  const libraryComponentType = props.item?.libraryComponent?.type || props.item?.type;
  const libraryComponentTitle = props.item?.title || props.item?.libraryComponent?.title;

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      if (isPublished) {
        return;
      }
      props.setIsActive(false);
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: t('deleteUpT'),
          subject: t('deleteThisSmartFileQuestionT'),
          description: t('deleteDescriptionT'),
          confirm: () => {
            dispatch(
              actionCreator(Playlist.updateMoveToTrash, {
                wrapperId: currentPage.wrapperId,
                state: true,
                id: currentPage.id,
                moveCallback: () => {
                  dispatch(
                    actionShowMessage({
                      type: MessageType.Regular,
                      text: t('smartFileDeletedT'),
                    }),
                  );
                  history.push(
                    `/content/smartfiles/${PLAYLIST_TYPES_REVERT[place]}`,
                  );
                },
              }),
            );
          },
          cancelText: t('cancelUpT'),
          okText: t('deleteUpT'),
        }),
      );
      return;
    }
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  const unsubscribeHandler = (e) => {
    const itemType = 'playlist';
    const historyType = 'smartfile';
    e.stopPropagation();
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: `${t('removeFromFeedModalDesc1T')}${
          isCoEdit ? t('removeFromFeedModalDesc2T') : ''
        }`,
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(ServiceUser.unsubscribe, {
              id: currentPage.id,
              history,
              type: itemType,
              historyType,
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  const removeFromChannel = (e) => {
    e.stopPropagation();
    if (isShowNotActiveRemoveFromChannel) return;
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: t('removeDescription1T'),
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(Channels.RemoveContentInSharedChannel, {
              contentId: currentPage.wrapperId,
              type: ITEM.Playlist,
              moveCallback: () => history.push(`/channel/${channelID}`),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  const downloadItemClickHandler = useCallback((e, filesArray, isLinkAdded, smartfileLink) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      dispatch(
        actionShowMessage({
          type: MessageType.DownloadWholePlaylist,
          itemName: 'playlist',
          mode: Group.processing,
          id: currentPage.id,
        }),
      );
      axiosAbortarium.generateNew(currentPage.id);
      dispatch(
        actionCreator(Playlist.DownloadAllDownloadablePlaylistItems, {
          playlistId: currentPage.id,
          playlistTitle: currentPage.title,
          filesArray,
          isLinkAdded,
          smartfileLink,
        }),
      );
      props.setIsActive(false);
      return;
    }

    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );
    axiosAbortarium.generateNew(libraryComponentId);
    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: libraryComponentTitle,
        elementType: libraryComponentType,
        isText: !!props.item.textComponent?.id,
        text: getItemName(props.item),
        owner: props.item.owner || { id: userID },
      }),
    );
    props.setIsActive(false);
  }, [currentPage.id, currentPage.title,
    isWholePlaylistOperation, libraryComponentId, libraryComponentTitle,
    libraryComponentType, props.item, userID]);

  const duplicateClickHandler = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    props.duplicateHandler && props.duplicateHandler();
  };

  const duplicatePlaylist = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    const newId = uuidv4();
    dispatch(actionDuplicatePlaylist(currentPage.id, newId));
  };
  const downloadPlaylist = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    props.handleDownload && props.handleDownload();
  };
  if (!show) return <></>;

  if (isTOC) {
    return (
      <ThreeDotsDropWrapperMaker
        data-parent="miniPage"
        ref={refMenu}
        onClick={stopPropagation}
        isWholePlaylistOperation={isWholePlaylistOperation}
        isShort={!isCanEdit}
        positionDown={positionDown}
        isTOC
      >
        <OptionsWrapper isFirst data-parent={DATA_PARENTS.checkItem}>
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
        </OptionsWrapper>
        {isCanDelete && (
          <OptionsWrapper
            data-parent={`${DATA_PARENTS.checkItem} "miniPage"`}
            disabled={
              (!itemOwner && isCoEdit)
              || (!itemOwner && isLocked)
              || isPlaceholder
              || isViewer
            }
            onClick={
              (!itemOwner && isCoEdit)
              || (!itemOwner && isLocked)
              || isPlaceholder
              || isViewer
                ? stopPropagation
                : duplicateClickHandler
            }
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.duplicate)}
          </OptionsWrapper>
        )}

        <OptionsWrapper
          data-parent={`${DATA_PARENTS.checkItem} "miniPage"`}
          disabled={!isCanRemix || isPlaceholder}
          onClick={
            !isCanRemix || isPlaceholder
              ? (e) => e.stopPropagation()
              : downloadItemClickHandler
          }
        >
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
        </OptionsWrapper>
        {isCanDelete && (
          <>
            <Divider />
            <OptionsWrapper
              isLast
              disabled={
                isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
              }
              onClick={
                isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                  ? stopPropagation
                  : deleteLinkPageClickHandler
              }
            >
              {!isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.delete)}
              {isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
            </OptionsWrapper>
          </>
        )}
      </ThreeDotsDropWrapperMaker>
    );
  }

  return (
    <ThreeDotsDropWrapperMaker
      isMaker={isMaker}
      data-parent={`${DATA_PARENTS.checkItem} "miniPage"`}
      ref={refMenu}
      onClick={stopPropagation}
      isWholePlaylistOperation={isWholePlaylistOperation}
      isShort={!isCanEdit}
      upvCard={upvCard}
      isCardView={props.isCardView}
      upvExpand={upvExpand}
      isTextCardView={props.isTextCardView}
      isMultiActionPanel={props.isMultiActionPanel}
      top={top}
      left={left}
    >
      {isWholePlaylistOperation && (
        <>
          <OptionsWrapper
            data-parent={DATA_PARENTS.checkItem}
            hasPopup
            disabled={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
            }
            onClick={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                ? stopPropagation
                : duplicatePlaylist
            }
          >
            <DuplicateSvg />
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.duplicate)}
          </OptionsWrapper>
        </>
      )}
      {isWholePlaylistOperation && (
        <>
          <OptionsWrapper
            data-parent={DATA_PARENTS.checkItem}
            hasPopup
            disabled={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
            }
            onClick={
              isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                ? stopPropagation
                : downloadPlaylist
            }
          >
            <DownloadSvg />
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
          </OptionsWrapper>
        </>
      )}
      {isCanDelete && isWholePlaylistOperation && (
        <>
          <Divider isDelete />
          <OptionsWrapper
            data-parent={DATA_PARENTS.checkItem}
            hasPopup
            isLast
            disabled={
              isViewer
              || (!itemOwner && isCoEdit)
              || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
            }
            onClick={
              isViewer
                || (!itemOwner && isCoEdit)
                || ((isCoEdit || isLocked) && !itemOwner && !isOwner)
                ? stopPropagation
                : deleteLinkPageClickHandler
            }
          >
            <DeleteSvg />
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
          </OptionsWrapper>
        </>
      )}
      {canUnsubscribe && isWholePlaylistOperation && (
        <>
          <Divider />
          <OptionsWrapper isLast onClick={unsubscribeHandler}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.unsubscribe)}
          </OptionsWrapper>
        </>
      )}
      {(isCanRemoveFromChannel || isShowNotActiveRemoveFromChannel) && isWholePlaylistOperation && (
        <>
          <Divider />
          <OptionsWrapper
            disabled={isShowNotActiveRemoveFromChannel}
            isLast
            onClick={removeFromChannel}
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.removeFromChannel)}
          </OptionsWrapper>
        </>
      )}
    </ThreeDotsDropWrapperMaker>
  );
};

export default ThreeDotsDropDownMaker;
