import React, { useState, useEffect, useCallback, useRef, RefObject, useMemo, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import EditPlaylist from '../../redux/playlists/types';

import { ReactComponent as FirstStepSvg } from '../../images/icons/aiProcessing/first_step.svg';
import { ReactComponent as SecondStepSvg } from '../../images/icons/aiProcessing/second_step.svg';
import { ReactComponent as ThirdStepSvg } from '../../images/icons/aiProcessing/third_step.svg';
import { ReactComponent as BatchSvg } from '../../images/icons/batch_actions.svg';
import { ReactComponent as ShareSvg } from '../../images/icons/batchActions/share_all.svg';
import { ReactComponent as SendSvg } from '../../images/2023/svg/send-2.svg';
import { ReactComponent as StopSvg } from '../../images/2023/svg/stop_ai.svg';
import { ReactComponent as CopySvg } from '../../images/icons/batchActions/copy.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { IRootState } from '../../redux/reducers';
import { openModalType, empty, aiProcessingStatusEnum, MessageType, DATA_PARENTS } from '../../utils/constants';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../redux/user/action';
import Playlist from '../../redux/playlists/types';
import { aIAgents, calcItemName } from '../../utils/ai/helper';
import {
  getLinkPagePosition,
  sanitizeToLoad,
  sanitizeHtml,
  parseMarkdown,
  rawAiTextToLexicalState,
  getManyNewLinkPagePositionByIndexForDuplicate,
  sanitizeToSave,
} from '../../utils/helpers';
import {
  Result, ImageShell, CustomButton,
  PlaylistAIInput, InputAreaWrapper,
  ExpandedFunctionalityWrapper,
  AITitle, Header, SingleResultInfo,
  SvgButtonWrapper, CloseWrapper,
  HeaderButtonsWrapper,
  AIButton, SectionTitle,
  ButtonsWrapper, Summary, ScrollableItemWrapper,
  ResultsWrapper, LoadingWrapper,
  AISpinnerWrapper, Spinner,
  ProcessingType, EstimatedTime,
  InputWrapper, SendButton,
  Textarea, SharedIndicator,
  SelectedItemsCounter,
  VerticalDivider, LoadingLine,
  StepWrapper, Note, FirstLine,
  Progress,
} from './ai_styled';
import { actionChangeLinkPageType, actionDuplicateLinkPageSInCurrentPage,
  actionEmptyAddLinkPageToPlaylist, actionSaveSearchResultsR, actionSaveSummarize } from '../../redux/currentPage/action';
import { ReactComponent as Close13Svg } from '../../images/icons/player/cross_close_13.svg';
import { actionChangeTextElementBlockReduxMaker, actionUnPublishSPlaylistS } from '../../redux/playlists/action';
import { smartFileItemType } from '../../shared/smartFile/constant';
import { actionShowMessage } from '../../redux/support/action';
import { PlaylistUI } from '../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';
import { BatchActionsPopup } from './BatchActionsPopup';
import UseKeydown from '../../utils/hooks/useKeydown';
import { isRoleInPlaylist } from '../../utils/permissions';
import { DefaultButton } from '../../components/unifiedStyledComponents';
import { AIAgentPopup } from './AIAgent';
import i18n from '../../i18n';

interface ILinkPage {
  id: string,
  name: string,
  title: string,
  isTextSavedToVectorDb: boolean | null,
  type: string,
  owner: {
    id: string,
  };
  isRemixLocked: boolean;
  textComponent: {
    id: string,
    parsedStatusByAi: string | null,
  };
  libraryComponent?: {
    title: string,
    size: string,
    parsedStatusByAi: string | null,
    summaryAI: string | null,
    type: string,
  }
  matchText?: string[],
  position: number,
}

interface ICurrentProcessingItems {
  processing: number,
  pending: number,
  all: number,
  completed: number,
  error: number,
}
interface ISingleSearhResult {
  singleClickHandler: (
      e: React.MouseEvent, index: number, linkPage:ILinkPage, ref:React.RefObject<HTMLDivElement> | null
  ) => void
  index: number,
  linkPage: ILinkPage,
  keywords: string[]
}

interface ISearhResults {
  ids: string[],
  matchesObj: { [key: string]: string[] },
  keywords: string[],
}

interface IAIAgent {
  name: string,
  icon: React.ReactElement,
  command: string,
}
const BetweenModal = 18;
const UpModal = 50;


const SingleSearhResult: React.FC<ISingleSearhResult> = ({ singleClickHandler, index, linkPage }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Result onClick={(e) => singleClickHandler(e, index, linkPage, ref)}>
      <FirstLine data-parent={DATA_PARENTS.checkItem}>
        <ImageShell ref={ref} data-parent={DATA_PARENTS.checkItem}>
          {PlaylistUI.createPreviewComponent(
            linkPage.libraryComponent?.type,
            linkPage.libraryComponent || {},
            linkPage?.id,
            '',
          )}
        </ImageShell>
        {calcItemName(linkPage, t)}
      </FirstLine>
      <SingleResultInfo data-parent={DATA_PARENTS.checkItem}>
        <p>
          {!!linkPage.matchText?.[0]?.length && <b>...{linkPage.matchText?.[0]}</b>}
          {linkPage.matchText?.[1]}...
        </p>
      </SingleResultInfo>
    </Result>
  );
};

const AIInput: React.FC<{
  currentProcessingItems: ICurrentProcessingItems,
  deleteHandler: (e: React.MouseEvent<Element | MouseEvent> | React.KeyboardEvent) => void,
}> = ({ currentProcessingItems, deleteHandler }) => {
  const { t } = useTranslation();
  const isProcessing = useMemo(() => (
    currentProcessingItems?.pending > 0 || currentProcessingItems?.processing > 0
  ), [currentProcessingItems]);

  const inputRef: RefObject<HTMLTextAreaElement> | null = useRef(null);
  const summaryRef: RefObject<HTMLDivElement> | null = useRef(null);
  const heightRef: RefObject<HTMLDivElement> | null = useRef(null);
  const isPrevent: MutableRefObject<boolean> | null = useRef(false);

  const { isViewer, isCoEdit } = isRoleInPlaylist;
  const [isExpandedForPreview, setExpandedForPreview] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentAgent, setCurrentAgent] = useState(aIAgents.pro);
  const [animate, setAnimate] = useState(false);
  const handleClickOutside = () => {
    setExpandedForPreview(false);
  };


  const [componentRefShowMaxHandler, isShowMax, setIsShowMax] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem,
  );

  const [componentRef, isExpanded, setIsExpanded] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem, handleClickOutside, isPrevent,
  );
  const handleOpenExpanded = () => {
    if (!isShowMax) setIsShowMax(true);
  };

  const [batchActionsPopupRef, isPopupOpened, setPopupOpened] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem, () => setExpandedForPreview(false),
  );
  const [aIAgentPopupRef, isAIAgentPopupOpened, setAIAgentPopupOpened] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem, () => setExpandedForPreview(false),
  );
  const {
    linkPages = [],
    manager,
    currentSummarize,
    currentSearchData,
    selectedItems,
    aiProcessing,
    id,
    wrapperId,
    title,
    currentProcessingTasks,
    singleUserShareState,
    userID,
    currentRole,
  } = useSelector((state: IRootState) => state.currentPage);
  const user = useSelector((state: IRootState) => state.user || empty);
  const dispatch = useDispatch();
  const isHasShare = !!Object.values(singleUserShareState).length;
  const sources: ILinkPage[] = useMemo(() => {
    return linkPages.filter((elem: ILinkPage) => elem.isTextSavedToVectorDb
      || elem.libraryComponent?.parsedStatusByAi === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI);
  }, [linkPages]);

  const [userTask, setUserTask] = useState<string>('');
  const [userTaskHeader, setUserTaskHeader] = useState<string>('');
  const [data, setData] = useState<ILinkPage[]>([]);
  const [isStartInside, setIsStartInside] = useState<boolean>(false);

  const [searchData, setSearchData] = useState<ISearhResults>({
    ids: [], matchesObj: {}, keywords: [] },
  );

  const handleReset = (withPrompt?: boolean) => {
    dispatch(actionSaveSummarize(''));
    setSearchData({ ids: [], matchesObj: {}, keywords: [] });
    setData([]);
    if (currentSearchData?.ids) {
      dispatch(actionSaveSearchResultsR([], {}, []));
    }
    if (withPrompt) {
      setUserTask('');
    }
  };

  const handleSelectAgent = (agent: IAIAgent) => {
    setCurrentAgent(agent);
    setUserTask('');
    setAIAgentPopupOpened(false);
    inputRef.current?.focus();
  };

  const createLinkPage = (destinationIndex: number, targetIndex?: number) => {
    const newPos = getLinkPagePosition(destinationIndex, linkPages, targetIndex);
    const linkPageId = uuid();
    const pageData = {
      id: linkPageId,
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const setSmartText = () => {
    setSearchData({ ids: [], matchesObj: {}, keywords: [] });
    const createdItem = createLinkPage(linkPages.length - 1);
    const newState = rawAiTextToLexicalState(sanitizeToSave(currentSummarize));
    const updateCBaction = (blockId: string) => {
      return (
        actionChangeTextElementBlockReduxMaker(
          blockId,
          newState,
          '',
          id,
          createdItem.id,
          smartFileItemType.title,
          true,
        ));
    };
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, id, null, updateCBaction));
    handleReset(true);
    setExpandedForPreview(false);
    setIsExpanded(false);
  };

  const singleClickHandler = useCallback(
    (event: React.MouseEvent, index: number, linkPage:ILinkPage, ref:React.RefObject<HTMLDivElement> | null) => {
      const elemPosition = ref?.current?.getBoundingClientRect();
      dispatch(
        actionOpenModal(openModalType.UPVPreview,
          { id: linkPage.id, index, mode: 'UPV', elemPosition, linkPages: data }),
      );
    }, [dispatch, data]);


  const handleAddToSF = () => {
    if (!Object.keys(selectedItems).length) {
      dispatch(
        actionShowMessage({
          type: MessageType.NoItemsSelected,
        }),
      );
      return;
    }
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { type: 'AddToSF' }),
    );
  };

  const filterTitles = () => {
    const hasItemsNotTitle = !!linkPages.filter((item: ILinkPage) => {
      return item.type !== 'elementTitle' && Object.keys(selectedItems).includes(item.id);
    }).length;
    if (!hasItemsNotTitle) {
      dispatch(actionShowMessage({
        type: MessageType.NeutralRegular,
        text: i18n.t('ActionNotSupportedT'),
      }));
      return false;
    }
    return true;
  };

  const handleSummarize = () => {
    if (!filterTitles()) return;
    const ids = Object.keys(selectedItems).length
      ? Object.keys(selectedItems) : linkPages.reduce((acc: string[], cur: ILinkPage) => {
        if (cur.libraryComponent?.parsedStatusByAi || cur.isTextSavedToVectorDb) {
          acc.push(cur.id);
        }
        return acc;
      }, []);
    dispatch(
      actionCreator(CurrentPage.Summarize, {
        linkPages: ids,
        userTask,
      }),
    );
  };

  const translateHandler = () => {
    if (!filterTitles()) return;
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { type: 'translate' }),
    );
  };

  const handleStop = () => {
    if (aiProcessing.isLoading && currentProcessingTasks) {
      setCurrentStep(0);
      handleReset(false);
      setIsExpanded(false);
      dispatch(
        actionCreator(CurrentPage.StopAIProcessingInSmartfile, {
          taskIds: currentProcessingTasks,
        }),
      );
    }
  };

  const handleSend = () => {
    setIsStartInside(true);
    if (currentAgent === aIAgents.search && aiProcessing.isLoading) {
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          isStop: true,
        }),
      );
      setIsExpanded(false);
      return;
    }
    if (aiProcessing.isLoading && currentProcessingTasks) {
      handleStop();
      return;
    }
    if (!userTask) return;
    const linkPagesForSend = (linkPages as ILinkPage[]).map(elem => elem.id);
    handleReset(false);
    setUserTaskHeader(userTask);
    if (!aiProcessing.isLoading && currentAgent === aIAgents.summarize) {
      handleSummarize();
      return;
    }
    if (currentAgent === aIAgents.search) {
      setSearchData({ ids: [], matchesObj: {}, keywords: [] });
      setData([]);
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          userTask,
          playlistManagerId: manager?.id ?? wrapperId,
          selectedIds: Object.keys(selectedItems),
          setSearchData,
        }),
      );
      return;
    }
    if (!aiProcessing.isLoading) {
      dispatch(
        actionCreator(CurrentPage.executeAITask, {
          linkPages: Object.keys(selectedItems).length ? Object.keys(selectedItems) : linkPagesForSend,
          userTask,
          playlistId: id,
          setSearchData,
        }),
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.code === 'Space' || e.key === 'Enter') {
      setAIAgentPopupOpened(false);
      const filteredAgent = Object.values(aIAgents)
        .filter(elem => elem.command === userTask.toLocaleLowerCase().trim());
      if (filteredAgent?.length) {
        e.preventDefault();
        setCurrentAgent(filteredAgent[0]);
        setUserTask('');
        setAIAgentPopupOpened(false);
        return;
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const calcSearchData = useCallback((searchResults: ISearhResults) => {
    const linkPagesMap = linkPages?.reduce((acc: { [key: string]: ILinkPage }, cur: ILinkPage) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    const responseLinkPages = searchResults.ids.map(mapId => {
      const linkPage = linkPagesMap[mapId];
      return { ...linkPage, matchText: searchResults.matchesObj[mapId] };
    });
    setData(responseLinkPages);
  }, [linkPages]);

  useEffect(() => {
    if (data.length !== searchData.ids.length && searchData.ids.length > 0
    ) {
      calcSearchData(searchData);
    }
  }, [searchData.ids, searchData.matchesObj, linkPages, data.length]);

  useEffect(() => {
    if (data.length !== currentSearchData?.ids?.length && currentSearchData?.ids?.length > 0
    ) {
      calcSearchData(currentSearchData);
      setSearchData(currentSearchData);
    }
  }, [currentSearchData?.ids, currentSearchData?.matchesObj, linkPages, data.length]);

  useEffect(() => {
    setTimeout(() => {
      isPrevent.current = user.openModal === openModalType.UPVPreview || (aiProcessing.isLoading || isProcessing) || !isShowMax;
    }, 100);
  }, [user.openModal, isShowMax, aiProcessing, isProcessing]);

  useEffect(() => {
    if (!(aiProcessing.isLoading || isProcessing)) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 900);
    } else if (animate) {
      setAnimate(false);
    }
  }, [aiProcessing, isProcessing]);


  useEffect(() => {
    if (aiProcessing.isLoading || isProcessing) {
      const increaseStep = () => {
        if (currentStep < 2) {
          setCurrentStep((prev) => prev + 1);
        }
      };
      setTimeout(increaseStep, 3000);
    } else if (currentStep >= 2) {
      setCurrentStep(0);
    }
  }, [aiProcessing.isLoading, isProcessing, currentStep]);

  useEffect(() => {
    if (aiProcessing.isLoading && aiProcessing.aiType !== 'ai_send') {
      setIsExpanded(true);
      setIsShowMax(true);
      if (!isStartInside) {
        setUserTaskHeader('');
      } else {
        setIsStartInside(false);
      }
    }
  }, [aiProcessing.isLoading, setIsExpanded, aiProcessing.aiType]);
  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '5px';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };


  const summaryMemo = useMemo(() => {
    return sanitizeHtml(parseMarkdown(sanitizeToLoad(currentSummarize)));
  }, [currentSummarize]);


  const handleOpenPopup = () => {
    if (!linkPages.length
      || !linkPages.some((lp: ILinkPage) => (
        lp.libraryComponent?.parsedStatusByAi === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI
        || lp.textComponent?.parsedStatusByAi === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI
      ))) {
      dispatch(
        actionShowMessage({ type: MessageType.EmptySF }),
      );
      return;
    }
    if (isExpanded) setIsExpanded(false);
    setPopupOpened(true);
  };

  const enableShare = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (isHasShare) {
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: t('unshareUpT'),
          subject: t('unshareThisSmartFileQuestionT'),
          description: t('unshareDescriptionT'),
          confirm: () => {
            dispatch(actionUnPublishSPlaylistS(id, wrapperId));
            dispatch(
              actionShowMessage({
                type: MessageType.Regular,
                text: t('smartFileUnsharedT'),
              }),
            );
          },
          cancelText: t('cancelUpT'),
          okText: t('unshareUpT'),
          type: 'unshare',
        }),
      );
      return;
    }
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  }, [dispatch, id, isHasShare, t, wrapperId]);

  const handleDownloadSelected = () => {
    const itemsForDownload = linkPages.reduce((acc: string[], cur: any) => {
      const isItemOwner = user.id === cur?.owner?.id;
      if (selectedItems[cur.id] && (isItemOwner || !cur.isRemixLocked)) {
        acc.push(cur.id);
      }
      return acc;
    }, []);
    if (itemsForDownload.length) {
      dispatch(
        actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
          playlistId: id,
          playlistTitle: title,
          filesArray: itemsForDownload,
          isLinkAdded: false,
        }),
      );
    }
  };

  const duplicateHandler = () => {
    const selectedLinkPages = linkPages.reduce((
      acc: {
        items: ILinkPage[],
        indexes: number[]
      },
      cur: ILinkPage,
      index: number,
    ) => {
      if (selectedItems[cur.id]) {
        acc.items.push(cur);
        acc.indexes.push(index);
      }
      return acc;
    }, { items: [], indexes: [] });

    const newPosAr = getManyNewLinkPagePositionByIndexForDuplicate(
      linkPages,
      selectedLinkPages.indexes,
    );
    newPosAr.map((newPos, index) => dispatch(
      actionDuplicateLinkPageSInCurrentPage(
        selectedLinkPages.items[index],
        newPos,
        id,
        uuid(),
      ),
    ),
    );
  };

  const allowedItems = useMemo(() => {
    return linkPages?.reduce((acc: { forSF: string[], forLibrary: string[] }, cur: ILinkPage) => {
      if (selectedItems[cur.id]
        && (!cur.isRemixLocked || cur.owner?.id === userID)
      ) {
        acc.forSF.push(cur.id);
        if (!cur.textComponent?.id) {
          acc.forLibrary.push(cur.id);
        }
      }
      return acc;
    }, { forLibrary: [], forSF: [] });
  }, [linkPages, selectedItems, userID]);

  const onAddItemsToLibraryClickHandler = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    if (allowedItems.forLibrary.length) {
      dispatch(actionCreator(Playlist.AddAllDownloadableItemsIntoLibrary, { items: allowedItems.forLibrary }));
    }
  };

  const isLocked = useMemo(() => {
    const lockedItems = linkPages.filter((elem: ILinkPage) => elem.isRemixLocked && selectedItems[elem.id]);
    return lockedItems.length === Object.keys(selectedItems).length;
  }, [linkPages, selectedItems]);

  const lockHandler = () => {
    dispatch(
      actionCreator(CurrentPage.UpdateRemixState, {
        linkPagesIdsMap: selectedItems,
        playlistId: id,
        value: !isLocked,
      }),
    );
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAIAgentPopupOpened(e.target.value?.[0] === '/');
    setUserTask(e.target.value);
  };

  UseKeydown(
    'ctrls',
    handleDownloadSelected,
    duplicateHandler,
    lockHandler,
    onAddItemsToLibraryClickHandler,
    handleAddToSF,
    handleSummarize,
    deleteHandler,
  );

  const isSendDisabled = userTask.length > 300
    || userTask.length < 1;

  const isViewerRole = isViewer[currentRole as 'viewer'];
  const isCoEditorRole = isCoEdit[currentRole as 'coEdit'];

  const isSharingDisabled = isViewerRole || isCoEditorRole || aiProcessing.isLoading || isProcessing;

  const expandModal = () => {
    if (isProcessing || aiProcessing.isLoading || !!data.length || currentSummarize) {
      setIsExpanded(true);
      setIsShowMax(true);
    }
  };

  const copyToClipboard = () => {
    const blobHtml = new Blob([summaryMemo], { type: 'text/html' });
    const blobText = new Blob([summaryMemo], { type: 'text/plain' });
    const data = [new ClipboardItem({
      'text/plain': blobText,
      'text/html': blobHtml,
    })];
    navigator.clipboard.write(data).then(() => {});
  };

  const onResetClick = () => {
    if (!(aiProcessing.isLoading || isProcessing)) {
      setTimeout(() => {
        handleReset(true);
      }, 1200);
      setIsExpanded(false);
    } else {
      setIsShowMax(false);
    }
  };
  useEffect(() => {
    if (!isExpanded) {
      onResetClick();
    }
  }, [isExpanded]);

  const isReady = !(aiProcessing.isLoading || isProcessing) && isExpanded;
  const heightItem = heightRef?.current?.getBoundingClientRect().height || 0;
  const maxOpenHeight = heightItem + BetweenModal;
  const isReadyToRead = !!(isReady && !isShowMax);
  const closeHeight = (isReadyToRead ? UpModal : 0) + ((animate && isReadyToRead) ? UpModal + UpModal : 0);
  const heightTop = (isExpanded && isShowMax) ? maxOpenHeight : closeHeight;
  const isDisplayAgents = Object.values(aIAgents)
    .filter(elem => elem.command.includes(userTask.toLocaleLowerCase().trim())).length > 0;
  return (
    <PlaylistAIInput
      ref={(ref) => {
        componentRef.current = ref;
        componentRefShowMaxHandler.current = ref;
      }}
      isExpanded={isExpanded}
      isExpandedForPreview={isExpandedForPreview}
    >
      {isPopupOpened && (
        <BatchActionsPopup
          popupRef={batchActionsPopupRef}
          selectedItems={selectedItems}
          setClosed={setPopupOpened}
          downloadHandler={handleDownloadSelected}
          summarizeHandler={handleSummarize}
          translateHandler={translateHandler}
          duplicateHandler={duplicateHandler}
          lockHandler={lockHandler}
          deleteHandler={deleteHandler}
          addToLibraryHandler={onAddItemsToLibraryClickHandler}
          addToSmartfileHandler={handleAddToSF}
          isLocked={isLocked}
          isDisabledByRole={isViewerRole}
        />
      )}
      {isAIAgentPopupOpened && isDisplayAgents && (
        <AIAgentPopup
          popupRef={aIAgentPopupRef}
          selectedItems={selectedItems}
          setClosed={setAIAgentPopupOpened}
          selectAgent={handleSelectAgent}
          inputValue={userTask}
          currentAgent={currentAgent}
        />
      )}
      {/* {isExpanded && ( */}
      <ExpandedFunctionalityWrapper
        ref={heightRef}
        onClick={handleOpenExpanded}
        isCanBeShow={animate}
        heightIndent={heightTop}
        isShowMax={isShowMax}
        data-parent={DATA_PARENTS.checkItem}
      >
        <Header data-parent={DATA_PARENTS.checkItem}>
          <AITitle>
            {!!userTaskHeader.trim() && (
              <h3 data-parent={DATA_PARENTS.checkItem}>{`${t('yourQueryT')} ${userTaskHeader}`}</h3>
            )}
          </AITitle>
          <HeaderButtonsWrapper>
            <CloseWrapper onClick={onResetClick} data-parent={DATA_PARENTS.checkItem}>
              <Close13Svg data-parent={DATA_PARENTS.checkItem} />
            </CloseWrapper>
          </HeaderButtonsWrapper>
        </Header>
        {!aiProcessing.isLoading && !isProcessing && (
        <ScrollableItemWrapper>
          {!!currentSummarize && !data.length && (
          <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('summaryT')}</SectionTitle>
          )}
          {!!currentSummarize && !data.length && (
          <b data-parent={DATA_PARENTS.checkItem}>{t('overviewT')}<br /></b>
          )}
          {data.length > 0 && (
          <ResultsWrapper data-parent={DATA_PARENTS.checkItem}>
            <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('foundInSourcesT')}</SectionTitle>
            {data.map((linkPage, index) => (
              <SingleSearhResult
                data-parent={DATA_PARENTS.checkItem}
                singleClickHandler={singleClickHandler}
                linkPage={linkPage}
                index={index + 1}
                keywords={searchData.keywords}
              />
            ))}
          </ResultsWrapper>
          )}
          {!!currentSummarize && !!data.length && (
          <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('extendedKnowledgeT')}:</SectionTitle>
          )}
          {currentSummarize && (
          <Summary
            dangerouslySetInnerHTML={{ __html: summaryMemo }}
            ref={summaryRef}
            data-parent={DATA_PARENTS.checkItem}
          />
          )}
          {currentSummarize && (
          <ButtonsWrapper>
            <AIButton
              onClick={setSmartText}
              isSave
              data-parent={DATA_PARENTS.checkItem}
            >
              {t('addToPlaylistDropdownOptionT')}
            </AIButton>
            <SvgButtonWrapper>
              <CopySvg onClick={copyToClipboard} data-parent={DATA_PARENTS.checkItem} />
            </SvgButtonWrapper>
          </ButtonsWrapper>
          )}
          {/* <HorizontalDivider />
              <ModalFooter>
                <LikeResultsSection>
                  <p>Like the result?</p>
                  <p className="save">Save query</p>
                </LikeResultsSection>
              </ModalFooter> */}
        </ScrollableItemWrapper>
        )}
        {(aiProcessing.isLoading || isProcessing) && (
        <LoadingWrapper data-parent={DATA_PARENTS.checkItem}>
          <AISpinnerWrapper>
            <LoadingLine data-parent={DATA_PARENTS.checkItem}>
              <Progress />
            </LoadingLine>
            <StepWrapper isActive>
              <FirstStepSvg data-parent={DATA_PARENTS.checkItem} />
            </StepWrapper>
            <StepWrapper isActive={currentStep > 0}>
              <SecondStepSvg data-parent={DATA_PARENTS.checkItem} />
            </StepWrapper>
            <StepWrapper isActive={currentStep === 2}>
              <ThirdStepSvg data-parent={DATA_PARENTS.checkItem} />
            </StepWrapper>
          </AISpinnerWrapper>
          <ProcessingType data-parent={DATA_PARENTS.checkItem}>

            {`${t('lookingTroughT')} ${Object.keys(selectedItems).length || t('small_allT')} ${t('sourcesT')}`}

          </ProcessingType>
          <Note data-parent={DATA_PARENTS.checkItem}>
            {t('youCanCloseT')}
            <br />
            {t('processWillContinueT')}
          </Note>
          <EstimatedTime data-parent={DATA_PARENTS.checkItem}>
            <p data-parent={DATA_PARENTS.checkItem}>{t('EstimatedTimeT')}</p>
            <DefaultButton onClick={handleStop} data-parent={DATA_PARENTS.checkItem}>{t('stopT')}</DefaultButton>
          </EstimatedTime>
        </LoadingWrapper>
        )}
      </ExpandedFunctionalityWrapper>
      {/* )} */}
      <InputAreaWrapper data-parent={DATA_PARENTS.checkItem}>
        <CustomButton
          onClick={handleOpenPopup}
          isActive={!!isPopupOpened}
          data-parent={DATA_PARENTS.checkItem}
          disabled={isProcessing || aiProcessing.isLoading}
        >
          {Object.keys(selectedItems).length > 0 ? (
            <SelectedItemsCounter data-parent={DATA_PARENTS.checkItem}>
              <span>{Object.keys(selectedItems).length}</span>
            </SelectedItemsCounter>
          ) : <BatchSvg data-parent={DATA_PARENTS.checkItem} />}
          {t('batchActionsT')}
        </CustomButton>
        <VerticalDivider data-parent={DATA_PARENTS.checkItem} />
        <InputWrapper
          onClick={expandModal}
          data-parent={DATA_PARENTS.checkItem}
        >
          <Tooltip
            text={`Agent: ${t(currentAgent.name)} \n ${t('typeForOptionT')}`}
            isShow={!isAIAgentPopupOpened}
            direction="down"
            place="ai_agent"
          >
            <div data-parent={DATA_PARENTS.checkItem}>
              {currentAgent.icon}
            </div>
          </Tooltip>
          <Textarea
            onKeyDown={handleKeyDown}
            onClick={() => setPopupOpened(false)}
            ref={inputRef}
            data-parent={DATA_PARENTS.checkItem}
            value={userTask}
            onInput={adjustHeight}
            onChange={handleChangeInput}
            placeholder={t('enterPromptT')}
            disabled={isProcessing || aiProcessing.isLoading}
          />
        </InputWrapper>
        <Tooltip
          text={aiProcessing.isLoading || isProcessing ? t('stopT') : t('enterToSendT')}
          direction="down"
          place="ai_send"
        >
          <SendButton
            onClick={handleSend}
            data-parent={DATA_PARENTS.checkItem}
            disabled={isSendDisabled}
            isLoading={aiProcessing.isLoading || isProcessing}
          >
            {(aiProcessing.isLoading || isProcessing)
              ? (
                <>
                  <Spinner isSmall data-parent={DATA_PARENTS.checkItem} className="ai_processing" />
                  {currentStep === 0 && <FirstStepSvg className="ai_step" data-parent={DATA_PARENTS.checkItem} />}
                  {currentStep === 1 && <SecondStepSvg className="ai_step" data-parent={DATA_PARENTS.checkItem} />}
                  {currentStep === 2 && <ThirdStepSvg className="ai_step" data-parent={DATA_PARENTS.checkItem} />}
                  <StopSvg data-parent={DATA_PARENTS.checkItem} className="ai_stop" />
                </>
              )
              : <SendSvg data-parent={DATA_PARENTS.checkItem} />}
          </SendButton>
        </Tooltip>
        <VerticalDivider />
        <CustomButton
          onClick={enableShare}
          isBrand
          disabled={isSharingDisabled}
          isShare
          data-parent={DATA_PARENTS.checkItem}
        >
          <ShareSvg data-parent={DATA_PARENTS.checkItem} />
          {t('shareT')}
          {isHasShare && !isViewerRole && !isCoEditorRole && <SharedIndicator />}
        </CustomButton>
      </InputAreaWrapper>
    </PlaylistAIInput>
  );
};

export default AIInput;
