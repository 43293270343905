import { useState, useEffect, useRef, MutableRefObject, SetStateAction, Dispatch } from 'react';

type ReturnType = [
  MutableRefObject<HTMLDivElement | null>,
      boolean | string,
  Dispatch<SetStateAction<boolean | string>>
];

export default function useComponentVisibleMouseDown(
  initialIsVisible: boolean | string,
  data?: string,
  callbackForOutsideClick?: (event: MouseEvent) => void,
  isPrevent?: MutableRefObject<boolean>,
  mark?:string,
): ReturnType {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean | string>(initialIsVisible);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleHideDropdown = (event: KeyboardEvent): void => {
    if (isPrevent?.current) return;
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (mark) {
      console.log('useComponentVisibleMouseDown mark start: ', mark);
    }
    if (isPrevent?.current) return;
    if (isComponentVisible === 'init') return;
    if (data) {
      if (
        ref.current
          && !ref.current.contains(event.target as Node)
          && !(event.target as HTMLElement)?.dataset?.parent?.includes(data)
          && !(event.target as HTMLElement)?.parentElement?.dataset?.parent?.includes(data)
      ) {
        setIsComponentVisible(false);
      }
      return;
    }
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (callbackForOutsideClick) callbackForOutsideClick(event);
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [isComponentVisible, data]);

  return [ref, isComponentVisible, setIsComponentVisible];
}
