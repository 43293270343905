import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { empty, emptyCallback } from '../../../../../utils/constants';
import { actionCreator } from '../../../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../../../redux/content/contentTypes';
import downloadStatus from '../../../../../utils/dataUtil';
import { ItemPlaceHolder } from '../../../../../components/MiniPlaylist/NewPlaylist/CardView/ItemPlaceHolder';
import NewEmptyState from '../../../../../components/MainContent';
import MultiSelectPanel from '../../../../../components/MultiSelect/MultiSelectPanel';
import LibraryContent from '../../../../../components/LibraryContentSelector/LibraryContent';

import { ContentWrapper, MakerLibWrapper } from './styled';
import useSelectAreaItems from '../../../../../utils/hooks/useSelectAreaItems';
import {
  actionRemoveAllSelected,
  actionRemoveSelectedPage,
  actionSelectedPage,
} from '../../../../../redux/selectedPage/action';

const Content = React.memo(function Content({
  items,
  selectorType,
  scrollDelta,
  permissions,
  counterSelectedPage,
  activeNavSlider,
  searchString,
  sortType,
  needToDelete,
  setNeedToDelete,
  chosenSmartView,
  chosenTag,
  isUsedMode,
  dataP,
  isPreventCloseMutableRef,
}) {
  const { contentIds, existMore, loadStatus } = useSelector(state => state.content);
  const isLargeRightBar = useSelector((state) => state.currentPage.isLargeRightBar);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isNotEmpty = !!contentIds.length;
  const isEmptyUsed = !items.length;
  const [hasMore, setHasMore] = useState(false);
  const isMaker = history.location.pathname.startsWith('/maker/');
  const scroller = useRef(null);
  const selectedPage = useSelector(state => state.selectedPage) || empty;

  const [onMouseDown, onMouseMove] = useSelectAreaItems(
    selectedPage,
    actionRemoveAllSelected,
    scroller,
    actionSelectedPage,
    selectorType,
    null,
    null,
    actionRemoveSelectedPage,
    scroller,
    isPreventCloseMutableRef,
  );

  const next = useCallback(() => {
    if (sortType === 'recent') {
      return;
    }

    const exSelector = searchString ? 'dynamicCollectionNew' : activeNavSlider;
    const exSelectorType = searchString
      ? { libFilter: {}, libFilterSearch: searchString }
      : selectorType;
    setHasMore(false);

    if (chosenSmartView.id) {
      chosenSmartView.initial = false;
      dispatch(
        actionCreator(
          ContentActionType.startUpload,
          {
            activeNavSlider: 'dynamicCollectionNew',
            selectorType: chosenSmartView,
            sortType,
          },
          'SmartView',
        ),
      );
      return;
    }

    if (chosenTag.id) {
      dispatch(
        actionCreator(
          ContentActionType.startUpload,
          {
            activeNavSlider: 'tag',
            selectorType: chosenTag.id,
            sortType,
          },
          'SideBarTabs',
        ),
      );
      return;
    }
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: exSelector,
          selectorType: exSelectorType,
          sortType,
        },
        'LibrarySideBar next UPVLib 2',
      ),
    );
  }, [
    sortType,
    chosenSmartView,
    activeNavSlider,
    chosenTag.id,
    searchString,
    selectorType,
    dispatch,
  ]);

  const isLoading = loadStatus === downloadStatus.init || loadStatus === downloadStatus.pending;

  const showItemPlaceholder = !isUsedMode && (existMore || (!isNotEmpty && isLoading));

  useEffect(() => {
    if (isEmptyUsed && isUsedMode) {
      setHasMore(false);
    } else setHasMore(existMore);
  }, [contentIds, isEmptyUsed]);

  useEffect(() => {
    // handling the case when, when the bar is expanded, the content is already loaded,
    // but the scroll does not move and a new download does not start
    if (isLargeRightBar && loadStatus === downloadStatus.success) {
      next();
    }
  }, [isLargeRightBar]);

  return (
    <ContentWrapper
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      ref={scroller}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={next}
        useWindow={false}
        hasMore={hasMore}
        loader={<div key={0}>{t('loadingT')}</div>}
        getScrollParent={() => scroller.current}
      >
        <MakerLibWrapper data-parent={dataP}>
          <LibraryContent
            contentData={items}
            isUsed={isUsedMode}
            permissions={permissions}
            isSimplifiedMultiselect={false}
            setSimplifiedMultiselect={emptyCallback}
            needToDelete={needToDelete}
            selectorType={selectorType}
            scrollDelta={scrollDelta}
            isRecentSelector={sortType === 'recent'}
            activeNavSlider={activeNavSlider}
            counterSelectedPage={counterSelectedPage}
            isUPV
          />
          {showItemPlaceholder && (
            <ItemPlaceHolder
              isFlex={sortType === 'recent'}
              type={activeNavSlider}
            />
          )}
          {!isNotEmpty && !isLoading && (
            <NewEmptyState
              place={activeNavSlider}
              isLoading={
                loadStatus === downloadStatus.init
                || loadStatus === downloadStatus.pending
              }
              selector={selectorType}
              onUploadClick={emptyCallback}
              createPage={emptyCallback}
              isSideBar
              showNewSmartView={emptyCallback}
              forUPV
            />
          )}
          {(isEmptyUsed && isUsedMode) && (
            <NewEmptyState
              place="library"
              selector="used"
              isSideBar
              showNewSmartView={emptyCallback}
              forUPV
            />
          )}
        </MakerLibWrapper>
      </InfiniteScroll>
      {counterSelectedPage >= 1 && (
        <MultiSelectPanel
          isMaker={isMaker}
          dataParent={`${dataP} miniPage`}
          isFavorite={selectorType === 'favorites'}
          setNeedToDelete={setNeedToDelete}
          counterSelectedPage={counterSelectedPage}
          contentType={
            activeNavSlider !== 'smartfiles' ? 'library' : 'playlist'
          }
        />
      )}
    </ContentWrapper>
  );
});

export default Content;
