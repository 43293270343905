import styled from 'styled-components/macro';

export const ButtonResumeRestartShadow = styled.div`
  background: rgba(147, 147, 147, 0.60);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 104;
`;
export const ButtonResumeRestartWrapper = styled.div`
  height: 32px;
  display: flex;
  margin-left: 13px;
  margin-right: 11px;
  position: relative;
`;
export const ButtonResumeRestartMain = styled.button`
  display: flex;
  width: 69.6px;
  height: 32px;
  padding: 10px 9.6px 10px 10px;
  justify-content: center;
  border-radius: 6px 0px 0px 6px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  color: #534B4F;
  font-family: RobotoFlex;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; 
  letter-spacing: 0.6px;
  text-transform: uppercase;

  &:hover {
    border: 0.6px solid #FFC72C;
    background: #FFEEBF;
    cursor: pointer;
  }
`;

export const ButtonResumeRestartChevronContainer = styled.button`
  display: flex;
  width: 22px;
  height: 32px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 0px 6px 6px 0px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  cursor: pointer;

  &:hover {
    border: 0.6px solid #FFC72C;
    background: #FFEEBF;
  }

  ${({ isActive }) => (isActive ? `   
    background: #FFEEBF;` : '')}
`;

export const BRRestartContainer = styled.div`
  width: 106px;
  height: 54px;
  padding: 12px 0px;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: white;
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.25);
  position: fixed;
  top: 39px;;
  left: 0;
  z-index: 105;
    ${({ isRestart }) => (isRestart ? `   
   top:${isRestart.top + 35}px;
   left:${isRestart.left}px;` : '')}
`;

export const BRRestartStripe = styled.div`
  display: flex;
  width: 105px;
  height: 30px;
  padding: 5px 12px 5px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: #484343;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  &:hover {
    background: #FFEEBF;
    cursor: pointer;
  }
`;
