import React from 'react';
import styled from 'styled-components/macro';

const ImageShell = styled.div`
  position: relative;
  transition: ease-out 0.25s;
  transition-property: width, height;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  width: ${({ width }) => (width ? `${width}px; ` : 'auto')}
  ${({ width }) => (width ? `min-width:${width}px; ` : '')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  ${({ upvExpand }) => (upvExpand
    ? 'width:unset; height:unset;'
    : ` 
   margin: 6px;`)}
`;

const Image = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  overflow-x: hidden;
`;

const PlaylistPhotoFrame = ({
  renderImage,
  width = 100,
  height,
  src,
  alt,
  previewRef,
  ...props
}) => {
  // const onClickHandler = (e) => {
  // if (!props.upvExpand) return;
  // e.stopPropagation();
  // };
  return (
    <ImageShell
      ref={previewRef}
      width={width}
      height={height}
      upvExpand={props.upvExpand}
      // onClick={onClickHandler}
    >
      {!src && props.children}
      {!!src && <Image src={src} alt={alt || 'alt'} />}
    </ImageShell>
  );
};

export default PlaylistPhotoFrame;
