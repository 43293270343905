import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { actionCloseModal } from '../../redux/user/action';
import styles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { AILanguagesList, DATA_PARENTS, MessageType } from '../../utils/constants';
import { ReactComponent as Close13Svg } from '../../images/icons/player/cross_close_13.svg';
import { ReactComponent as EmptyLanguagesSvg } from '../../images/icons/batchActions/empty_languages.svg';
import { ReactComponent as EmptySFsSvg } from '../../images/icons/batchActions/empty_sfs.svg';
import { CloseWrapper } from '../../pages/Maker/ai_styled';
import { CurrentPage } from '../../redux/currentPage/types';
import { actionShowMessage } from '../../redux/support/action';

const cx = classNames.bind(styles);

const BatchActionsUniversal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    dataPayload: { element, type },
  } = useSelector((redux) => redux.user);

  const close = () => {
    dispatch(actionCloseModal());
  };

  const history = useHistory();
  const [search, setSearch] = useState('');

  const playlists = useSelector((state) => state.playlists);
  const currentPage = useSelector((state) => state.currentPage);
  const recentLang = useSelector((state) => state.user.recentLang);
  const [displayItems, setDisplayItems] = useState([]);

  const translate = (lang) => {
    const filteredRTBs = currentPage.linkPages.reduce((acc, cur) => {
      if ((cur.id === element?.id || currentPage.selectedItems[cur.id]) && cur?.textComponent?.id) {
        acc.push(cur.id);
      }
      return acc;
    }, []);
    if (!filteredRTBs.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.NoTextContent,
        }),
      );
      close();
      return;
    }
    const notTranslatedItemsCount = Object.values(currentPage.selectedItems).length - filteredRTBs.length;
    if (!element && notTranslatedItemsCount > 0) {
      dispatch(
        actionShowMessage({
          type: MessageType.ContentSkipped,
          count: notTranslatedItemsCount,
        }),
      );
    }
    dispatch(
      actionCreator(CurrentPage.AITranslateProcessing,
        { linkPageIds: filteredRTBs, status: true }),
    );
    dispatch(
      actionCreator(CurrentPage.Translate, {
        linkPages: filteredRTBs,
        lang,
      }),
    );
    close();
  };

  const movePageToPlaylist = useCallback((playlistId, title) => (e) => {
    e?.stopPropagation();
    const playlistIdTo = playlistId || uuid();
    const items = Object.keys(currentPage.selectedItems);
    if (!element && items?.length > 0) {
      dispatch(
        actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
          fromPlaylist: currentPage.id,
          history,
          toPlaylist: playlistIdTo,
          selectedItems: items,
          title,
        }),
      );
    } else {
      dispatch(
        actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
          fromPlaylist: currentPage.id,
          history,
          toPlaylist: playlistIdTo,
          selectedItems: [element?.id || currentPage.id],
          title,
        }),
      );
    }

    close();
  }, [currentPage.id, currentPage.selectedItems, dispatch, element, history]);


  useEffect(() => {
    if (type === 'AddToSF') {
      dispatch(
        actionCreator(EditPlaylist.RequestUserPlaylists, { parentId: element?.libraryComponent?.id }),
      );
    }
  }, []);

  useEffect(() => {
    const items = type === 'AddToSF' ? playlists : AILanguagesList;
    setDisplayItems(
      Object.values(items).filter(
        (item) => {
          if (type === 'AddToSF') {
            if (!item.movedToTrash) {
              return (item?.title || t('defaultSmartFileTitleT')).toLowerCase().includes(search.toLowerCase());
            }
          } else {
            return item?.toLowerCase().includes(search.toLowerCase());
          }
        },
      ),
    );
  }, [playlists, search, type, t]);

  const modalTitle = type === 'translate'
    ? t('translateT') : t('addToAnotherSmartfileDropdownOptionT');
  const modalPlaceholder = type === 'translate'
    ? t('searchLanguageT') : t('searchaSmartFileT');

  return (
    <div onClick={close} style={{ overflowY: 'hidden' }} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'AddToSFModal')}
      >
        <div className={cx('title_wrapper')}>
          <div className={cx('title')}>{modalTitle}</div>
          <CloseWrapper onClick={close} data-parent={DATA_PARENTS.checkItem}>
            <Close13Svg data-parent={DATA_PARENTS.checkItem} />
          </CloseWrapper>
        </div>
        <input
          value={search}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={modalPlaceholder}
          className={cx('input_search')}
          data-parent={`${DATA_PARENTS.checkItem} miniPage`}
        />
        {type === 'AddToSF' && (
          <div
            onClick={movePageToPlaylist()}
            data-parent={DATA_PARENTS.checkItem}
            className={cx('item')}
          >
            {t('createNewSmartFileT')}
          </div>
        )}
        <div
          className={cx('items_list_wrapper')}
          data-parent={`${DATA_PARENTS.checkItem} miniPage`}
        >
          {type === 'translate' && recentLang && (
            <div data-parent={DATA_PARENTS.checkItem} className={cx('items_section')}>
              Recent
            </div>
          )}
          {type === 'translate' && recentLang && (
            <div data-parent={DATA_PARENTS.checkItem} className={cx('item')} onClick={() => translate(recentLang)}>
              {recentLang}
            </div>
          )}
          {displayItems.map((res) => (
            <div
              data-parent={`${DATA_PARENTS.checkItem} miniPage`}
              onClick={type === 'translate' ? () => translate(res) : movePageToPlaylist(res.id, res.title)}
              key={res.id ?? res}
              className={cx('item')}
            >
              {type === 'translate' ? (
                <div data-parent={DATA_PARENTS.checkItem}>
                  {res}
                </div>
              ) : (
                <div data-parent={DATA_PARENTS.checkItem}>
                  {' '}
                  {res.title ? res.title : t('defaultSmartFileTitleT')}
                </div>
              )}
            </div>
          ))}
          {!displayItems.length && search && (
            <div data-parent={DATA_PARENTS.checkItem} className={cx('empty_state')}>
              {type === 'AddToSF' ? <EmptySFsSvg /> : <EmptyLanguagesSvg />}
              <p>{t(type === 'AddToSF' ? 'SFUnavailableT' : 'languageUnavailableT')}</p>
              <p>{t(type === 'AddToSF' ? 'SFUnavailable2T' : 'languageUnavailable2T')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BatchActionsUniversal;
