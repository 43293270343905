import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditPlaylist from '../../redux/playlists/types';
import { ReactComponent as ColorsIconSvg } from '../../images/2023/svg/color_cover.svg';
import { ReactComponent as UploadIconSvg } from '../../images/2023/svg/upload_cover.svg';
import { ReactComponent as DropIconSvg } from '../../images/2023/svg/drop_icon.svg';
import { EditCoverPopupWrapper, TabsWrapper, TabContent, DropWrapper } from './ts_styled';
import FolderColorPicker from './FolderColorPicker';
import { actionChangeDefaultColorPlaylist } from '../../redux/playlists/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { IRootState } from '../../redux/reducers';

interface IEditCoverPopup {
  refMenu: React.MutableRefObject<HTMLDivElement> | null,
  isActive: boolean,
  top: number,
  left: number,
  refForScroll: HTMLDivElement | null,
  onDrop: (e: React.DragEvent<HTMLDivElement>, isFile?: boolean) => void,
  setImageUploading: (value: boolean) => void,
  setIsActive: (value: boolean) => void,
  isDrag: boolean,
  setDrag: (value: boolean) => void,
  isDragFromDevice:  boolean,
  setDragFromDevice: (value: boolean) => void,
}

const EditCoverPopup: React.FC<IEditCoverPopup> = ({
  refMenu,
  isActive,
  top,
  left,
  refForScroll,
  onDrop,
  setImageUploading,
  setIsActive,
  isDrag,
  setDrag,
  isDragFromDevice,
  setDragFromDevice,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, img, cropUrl, defaultPreviewColor } = useSelector((state: IRootState) => state.currentPage);
  const [currentTab, setCurrentTab] = useState('image');
  const inputFile = useRef<HTMLInputElement | null>(null);
  const setCurrentColor = useCallback((color: string) => {
    dispatch(actionChangeDefaultColorPlaylist(color, id));
  }, [dispatch, id]);
  const onButtonClick = () => {
    inputFile?.current?.click();
    setImageUploading(true);
  };

  const dropDepthRef = useRef(0);

  const onDragLeave = (e: any) => {
    dropDepthRef.current -= 1;
    if (dropDepthRef.current > 0) return;
    e.stopPropagation();
    e.preventDefault();
    setDrag(false);
    setDragFromDevice(false);
  };

  const onDragEnter = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    dropDepthRef.current += 1;
    if (e.dataTransfer?.types.filter((i: string) => i === 'Files').length) {
      setDragFromDevice(true);
    }
    setDrag(true);
  };

  const clearCoverByPickColor = useCallback(() => {
    if (img?.src || cropUrl) {
      dispatch(actionCreator(EditPlaylist.MakerDeleteImage));
    }
  }, [cropUrl, dispatch, img?.src]);
  const handleSubmit = (e: React.ChangeEvent | React.DragEvent) => {
    onDrop(e as React.DragEvent<HTMLDivElement>, true);
    setIsActive(false);
  };
  if (!isActive) return <></>;
  return (
    <EditCoverPopupWrapper ref={refMenu} top={top} left={left}>
      <TabsWrapper>
        <TabContent
          className="text_unified-sm"
          isActive={currentTab === 'image'}
          onClick={() => setCurrentTab('image')}
        >
          <UploadIconSvg />
          {t('uploadT')}
        </TabContent>
        <TabContent
          className="text_unified-sm"
          isActive={currentTab === 'color'}
          onClick={() => setCurrentTab('color')}
        >
          <ColorsIconSvg />
          {t('ColorsT')}
        </TabContent>
      </TabsWrapper>
      {currentTab === 'image' && (
        <DropWrapper
          onDrop={onDrop}
          className={cn({ dragged_over: isDragFromDevice })}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          <DropIconSvg />
          <p className="text_unified-sm">{t('DNDFilesT')}</p>
          <p className="text_unified-sm">{t('or ')}
            <span onClick={onButtonClick} className="text_unified-sm browse">{t('browseT')}</span>
            </p>
          <input
            type="file"
            name="file"
            accept={`image/png,
                    image/jpeg,
                    image/gif,
                    image/svg+xml,
                    image/bmp,
                `}
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleSubmit}
          />
        </DropWrapper>
      )}
      {currentTab === 'color' && (
        <FolderColorPicker
          isCheckDisplayed={!(img?.src || cropUrl)}
          activeColor={defaultPreviewColor ?? 'yellow'}
          setCurrentColor={setCurrentColor}
          clearCoverByPickColor={clearCoverByPickColor}
          refForScroll={refForScroll}
          isCutHeader
        />
      )}
    </EditCoverPopupWrapper>
  );
};

export default EditCoverPopup;
