import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import {
  actionRemoveLinkPageFromPlaylist,
  actionToggleLibraryDrag,
  actionUpdateDraggableBlocks,
  actionPageWillDownload,
  actionUpdateCollapseLinkPageRS,
} from '../../../redux/currentPage/action';
import SupportAction from '../../../redux/support/types';
import {
  DATA_PARENTS,
  SHARED_PLAYLIST_URL,
  stopPropagation,
} from '../../../utils/constants';
import { actionChangeTextElementBlockReduxMaker } from '../../../redux/playlists/action';

import { actionAddPayloadUnifyHistory } from '../../../redux/history/actions';
import SixDotsItem from './SixDotsItem';
import DeleteContentButton from './DeleteContentButton';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { isRoleInPlaylist } from '../../../utils/permissions';
import PlaylistElementCaption from './PlaylistElementCard/PlaylistElementCaption';
import ThreeDotsDropDownMaker from '../ThreeDotsDropDownForMaker';
import { smartFileItemType } from '../../../shared/smartFile/constant';
import TextEditor from '../../../features/TextEditor/TextEditor';
import LexicalViewer from '../../../features/LexicalViewer/LexicalViewer';
import EditPlaylist from '../../../redux/playlists/types';
import { ReactComponent as ChevronDown12Svg } from '../../../images/icons/minimize_rtb.svg';
import { ReactComponent as TranslateSvg } from '../../../images/icons/batchActions/translate_green.svg';
import { calcHasIndention } from '../../../utils/helpers';
import { UsedByAIIndicatorComp } from '../../../components/UsedByAIIndicator/UsedByAIIndicator';

const cn = classNames.bind(rawStyles);


const LexTextElement = ({
  item,
  playlistId,
  isDragHover,
  selectedIds,
  selectedIndex,
  setSelectedIndex,
  setSelectedIds,
  itemIndex,
  refSelectedBlocks,
  setIsDraggingText,
  itemStylesObject,
  isExpandedView,
  isCompressView,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isForSharedToWeb = history.location.pathname.startsWith(`/${SHARED_PLAYLIST_URL}`);
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;
  const { isViewer } = isRoleInPlaylist;
  const [itemRef, , setIsEdit] = useComponentVisibleMouseDown(false, item.id);
  const textRef = useRef(null);
  const [menuRef, isActive, setIsActive] = useComponentVisible(false, DATA_PARENTS.checkItem);
  const [plainText, setPlainText] = useState('');
  const [isEditorWidthFocus, setIsEditorWidthFocus] = useState(false);
  const [isCanCollapsed, setIsCanCollapsed] = useState(false);
  const canUpdForRedoUndo = useRef(null);

  const { newBlockId, selectedItems, linkPages } = useSelector((reduxState) => (reduxState.currentPage));
  const [threeDotRef, isThreeDotsActive, setIsThreeDotsActive] = useComponentVisible(false, 'miniPage');

  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );

  const cmdZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+z'],
  );
  const cmdShZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+shift+z'],
  );
  const socketUpd = useSelector(
    (reduxState) => reduxState.shortcut?.combination.socketUpd,
  );
  const timerId = useRef(null);

  const hasIndention = useMemo(
    () => calcHasIndention(linkPages, itemIndex),
    [currentPage.linkPages, props.itemIndex]);

  const { channelId = null, id: sharedToWebPlaylistID, type } = useParams();
  const isChannels = !!channelId;

  const isAuthor = userId === item?.owner?.id;
  const otherOwner = !isAuthor && item.owner;

  useEffect(() => {
    if (cmdZ || cmdShZ || socketUpd) {
      setCanUpdForRedo();
    }
  }, [cmdZ, cmdShZ, socketUpd]);

  useEffect(() => {
    const minHeightForCollapse = isAuthor ? 50 : 62;
    if (isAuthor && !item.isTextSavedToVectorDb) {
      const index = item.textComponent.createdByAI ? 0 : 1;
      const textElement = textRef.current?.children[0]?.children[index];
      setIsCanCollapsed(textElement?.getBoundingClientRect().height > minHeightForCollapse);
    } else {
      const textElement = textRef?.current?.children[0];
      setIsCanCollapsed(textElement?.getBoundingClientRect().height > minHeightForCollapse);
    }
  }, [
    item?.textComponent?.state, item.isTextSavedToVectorDb, textRef.current?.getBoundingClientRect().height,
  ]);
  console.log({ isCanCollapsed }, 'item.isCollapsed', item.isCollapsed);

  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  const clear = () => {
    refSelectedBlocks.current.forEach((block) => {
      if (block) {
        block.removeAttribute('class');
      }
    });
    setSelectedIds([]);
    setSelectedIndex([]);
    dispatch(actionToggleLibraryDrag(false));
    dispatch(actionUpdateDraggableBlocks([]));
  };

  const onCardClickHandler = (e) => {
    if (isViewer[props.currentRole]) {
      e.stopPropagation();
      return;
    }
    if (item.textComponent?.createdByAI) {
      e.stopPropagation();
      setIsEdit(true);
      dispatch(actionChangeTextElementBlockReduxMaker(
        item.textComponent.id,
        item.textComponent.state,
        '',
        playlistId,
        item.id,
        smartFileItemType.richText,
        false,
      ));
    }
  };
  const onCollapseHandler = (e) => {
    console.log(11111111);
    e.stopPropagation();
    switch (e.detail) {
      case 1:
        dispatch(actionUpdateCollapseLinkPageRS({
          isCollapsed: !item.isCollapsed,
          linkPageId: item.id,
        }));
        break;
      case 2:
        dispatch(actionUpdateCollapseLinkPageRS({
          isCollapsed: item.isCollapsed,
          isCollapsedAll: true,
        }));
        break;
    }
  };

  const onDragTextHandler = () => {
    if (isViewer[props.currentRole]) {
      return;
    }
    setIsDraggingText((prev) => !prev);
  };

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  const newGoToPlaylist = (event, index) => {
    event.stopPropagation();
    if (!props.isForSharedToWeb) dispatch(actionPageWillDownload());
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }

    if (isForSharedToWeb) {
      dispatch(
        actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
      );
      history.push(`/shared_player/shared/${sharedToWebPlaylistID}/${index}`);
      return;
    }
    const defaultChannel = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';
    const exPlaylistId = type === 'shared' ? sharedToWebPlaylistID : currentPage.id;
    const firstChannelId = type === 'publish' ? defaultChannel : type === 'shared' ? 'shared' : 'preview';
    history.push(`/player/${firstChannelId}/${exPlaylistId}/${index}`);
  };

  const onChange = useCallback((jsonEditorState) => {
    if (!jsonEditorState || !isAuthor) return;
    dispatch(actionChangeTextElementBlockReduxMaker(
      item.textComponent.id,
      jsonEditorState,
      '',
      playlistId,
      item.id,
      smartFileItemType.title,
      item.textComponent.createdByAI,
    ));
    if (item.textComponent.parsedStatusByAi) {
      dispatch(actionCreator(EditPlaylist.ClearSavedTextS, {
        textComponentId: item.textComponent.id,
        linkPageId: item.id,
      }));
    }
  }, [item.textComponent.createdByAI, item.textComponent.parsedStatusByAi]);


  const onBlurHandler = useCallback((state) => {
    dispatch(actionCreator(EditPlaylist.removeNewInLinkPage, { state }));
  }, [dispatch]);

  return (
    <div
      className={cn('six_dot_balancer', {
        isCompressView,
        hoverable: !isDragHover && !isViewer[props.currentRole],
        active: isActive,
      })}
    >
      <div
        className={cn('empty_element_wrapper', { is_expanded_view: isExpandedView, has_indention: hasIndention })}
      >
        <div
          className={cn('text_element', {
            hoverable: !isDragHover,
            dragginOver: isDragHover,
            has_indention: hasIndention,
            viewMode: isViewer[props.currentRole],
            notEdit:
              (userId !== item?.owner?.id)
              || isViewer[props.currentRole],
            blink_it: props.blinkId?.includes(item.id),
          })}
          ref={itemRef}
          onDragEnd={props.onDragEndHandler}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onDragStart={props.dragStartHandler}
          // draggable={!isViewer[props.currentRole] && isAuthor}
        >
          <SixDotsItem
            isExpandedView={isExpandedView}
            clear={props.clear}
            createHandler={props.createHandler}
            onDragStartHandler={props.onDragStartHandler}
            onDragEndHandler={props.onDragEndHandler}
            title={t('textBlockUpT')}
            playlistId={playlistId}
            menuRef={menuRef}
            dragStartHandler={props.dragStartHandler}
            isActive={isActive}
            setIsActive={setIsActive}
            isText
            isTitle
            item={item}
            itemStylesObject={itemStylesObject}
            otherOwner={otherOwner}
            userRole={props.userRole}
            duplicateHandler={props.duplicateHandler}
            isEmptyPlaceholder
            isViewer={isViewer[props.currentRole]}
            currentRole={props.currentRole}
            createApproveButton={props.createApproveButton}
            addCaptionToLinkPage={props.addCaptionToLinkPage}
            removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
            deleteApproveButton={props.deleteApproveButton}
            ownerID={ownerID}
            isCompressView={isCompressView}
            refHoverForPositionAction={props.refHoverForPositionAction}
          />
          <UsedByAIIndicatorComp
            hasIndention={hasIndention}
            parsedStatusByAi={item.textComponent.parsedStatusByAi}
            linkPage={item}
          />
          <div
            onClick={onCardClickHandler}
            data-parent={item.id}
            className={`${cn('text_container', 'rich_text', {
              selected: selectedItems[item.id],
              isAIGenerated: item.textComponent.createdByAI,
              isCollapsed: isCanCollapsed && item.isCollapsed,
              isCanCollapsed,
              notEdit:
                (userId !== item?.owner?.id)
                || isViewer[props.currentRole],
            })} highlightedPlace`}
            // draggable
            onDragStart={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }
            }
            onMouseDown={(e) => {
              e.isText = true;
            }}
            onDragEnd={onDragTextHandler}
          >
            {isCanCollapsed && (
              <div onClick={onCollapseHandler} className={cn(rawStyles.collapsed_chevron_wrap)}>
                <div className={cn(rawStyles.collapsed_chevron, {
                  isCollapsed: isCompressView && item.isCollapsed, isViewer: !isAuthor,
                })}
                >
                  <ChevronDown12Svg />
                </div>
              </div>
            )}
            <div
              className={cn('counter', {
                counter_visible: isEditorWidthFocus,
                counter_grey: plainText.length < 600,
                counter_yellow:
                  plainText.length >= 600 && plainText.length < 900,
                counter_red: plainText.length >= 900,
              })}
            >
              {plainText.length}/1000
            </div>
            {item.textComponent.isTranslating && (
              <div className={cn('translate')}>
                <TranslateSvg />
                <div>{t('TranslatingT')}</div>
              </div>
            )}
            {(!isAuthor && !item.textComponent.isTranslating) && (
            <div ref={textRef}>  <LexicalViewer state={item.textComponent?.state || ''} /> </div>
            )}
            {(isAuthor && !item.textComponent.isTranslating) && (
              <div ref={textRef}>
                <TextEditor
                  onBlurHandler={onBlurHandler}
                  setIsDNDInTextBlock={props.setIsDNDInTextBlock}
                  isAIGenerated={item.textComponent.createdByAI}
                  isAITranslated={item.textComponent.translatedByAI}
                  isActive={newBlockId && (newBlockId === item.textComponent.id)}
                  onChange={onChange}
                  idKey={item.id}
                  state={item.textComponent?.state || ''}
                  isCollapsed={item.isCollapsed}
                />
              </div>
            )}
          </div>
          {isThreeDotsActive && (
            <ThreeDotsDropDownMaker
              playlistType={props.playlistType}
              show={isThreeDotsActive}
              refMenu={threeDotRef}
              itemRef={itemRef}
              isTitle
              isActive={isThreeDotsActive}
              setIsActive={setIsThreeDotsActive}
              playlistId={currentPage.id}
              isContentEditable={false}
              socketId={currentPage.socketId}
              isOwner={props.isOwner}
              item={item}
              goToItem={stopPropagation}
              currentPage={currentPage}
              itemIndex={itemIndex}
              play={(e) => newGoToPlaylist(e, itemIndex + 1)}
              currentRole={props.currentRole}
              upvCard
              isTextCardView
            />
          )}

        </div>

        <PlaylistElementCaption
          caption={item.caption}
          linkPageId={item.id}
          isShowCaption={item.isShowCaption}
          isNew={item.isNew}
        />
        {!isViewer[props.currentRole]
          && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
            <DeleteContentButton
              isText
              refHoverForPositionAction={props.refHoverForPositionAction}
              deleteHandler={deleteHandler}
              isCompressView={isCompressView}
            />
        )}
      </div>
    </div>
  );
};

export default LexTextElement;
