import { applyNewState } from '../components/DurationPicker/helpers';
import i18n from '../i18n';

export const SECS_IN_MINUTES = 60;
export const MINS_IN_HOUR = 60;
export const SECONDS_IN_HOUR = 3_600;
export const SECONDS_IN_DAY = 86_400;
export const SECONDS_IN_MONTH = 2_628_000;
export const SECONDS_IN_YEAR = 31_536_000;
export const MILLISECONDS_IN_MIN = 60000;

export const pluralize = (word, count) => {
  if (!word || typeof word !== 'string') throw new Error('TypeError: Word must be a String type');
  switch (count) {
    case 0:
    case 1: {
      return `${word}`;
    }

    default: {
      return `${word}s`;
    }
  }
};

const getNavigatorLanguage = () => (navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.userLanguage
      || navigator.language
      || navigator.browserLanguage
      || 'en');

export const createDate = () => {
  const date = new Date();
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  return date.toLocaleDateString(getNavigatorLanguage(), options);
};

export const convertToDifferanceDates = (
  availableTo,
  currentDate = new Date(),
) => {
  const diff = availableTo - currentDate.getTime() / 1000;
  const days = Math.floor(diff / (60 * 60 * 24));
  const hours = Math.floor((diff / 3600) % 24);
  const minutes = Math.floor((diff / 60) % 60);
  const seconds = Math.floor(diff % 60);

  let diffStr = '';
  if (days) {
    diffStr = `${days} DAYS  ${hours} HOURS`;
  } else {
    diffStr = `${hours}H ${minutes}M ${seconds}S`;
  }

  return diffStr;
};

export const convertDateToReadable = (seconds, isAvailableTo) => {
  const res = new Date();
  if (typeof seconds !== 'string' && seconds) {
    res.setTime(seconds * 1000);
  } else if (isAvailableTo) {
    res.setTime(res.getTime() + SECONDS_IN_DAY * 1000);
  }
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return res.toLocaleDateString(getNavigatorLanguage(), options);
};
export const convertDate = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch
  const now = new Date(); // Epoch

  const tomorrow = new Date((seconds - 86400) * 1000); // Epoch
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const resLocalDate = res.toLocaleDateString(getNavigatorLanguage(), options);
  if (
    resLocalDate === now.toLocaleDateString(getNavigatorLanguage(), options)
  ) {
    return `${i18n.t('TodayT')}, ${res.toLocaleTimeString(getNavigatorLanguage(), {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }
  // tomorrow.setSeconds(seconds - 86400);
  if (
    tomorrow.toLocaleDateString(getNavigatorLanguage(), options)
    === resLocalDate
  ) {
    return `${i18n.t('TodayT')}, ${res.toLocaleTimeString(getNavigatorLanguage(), {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }
  return resLocalDate;
};

export const convertUPVDate = (seconds) => {
  const utcDate = new Date(seconds * 1000);
  const now = new Date();
  // case today
  if (utcDate.getDate() == now.getDate()) {
    return `${i18n.t('TodayT')}, ${utcDate.toLocaleString(i18n.language, { hour: '2-digit' })}:${
      (utcDate.getMinutes() < 10 ? '0' : '') + utcDate.getMinutes()
    }`;
  }
  return `${utcDate.toLocaleString(i18n.language, {
    day: '2-digit',
  })} ${utcDate.toLocaleString(i18n.language, {
    month: 'short',
  })}.  ${utcDate.toLocaleString(i18n.language, { year: 'numeric' })}`;
};

export const convertDatePlaylist = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch

  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return res.toLocaleDateString(getNavigatorLanguage(), options);
};

export const convertDatePage = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch

  return res.toLocaleTimeString(getNavigatorLanguage(), { hour12: false });
};

export const convertDateLibFilter = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch

  const options = { month: 'short', day: 'numeric' };
  return res.toLocaleDateString(getNavigatorLanguage(), options);
};

export const convertDateListView = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch

  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return res.toLocaleDateString(getNavigatorLanguage(), options);
};

export const convertDateContact = (seconds) => {
  if (typeof seconds === 'string' || !seconds) return false;
  const res = new Date(seconds * 1000); // Epoch

  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return res.toLocaleDateString(getNavigatorLanguage(), options);
};

export const convertTimeMinutesToHours = (minutes) => {
  let clearValue = `${Math.ceil(+minutes)} min`;
  if (!minutes || Number.isNaN(+minutes)) clearValue = '0 min';
  if (+minutes > 59) {
    const hours = Math.floor(minutes / 60);
    const minReminder = minutes % 60;
    const hoursString = `${hours} ${hours % 10 === 1 ? 'hour' : 'hours'}`;
    const minutesString = minReminder
      ? `${minReminder} ${minReminder % 10 === 1 ? 'min' : 'mins'}`
      : '';

    clearValue = `${hoursString} ${minutesString}`;
  }
  return clearValue;
};

export const convertToCashMachineView = (inputValue) => {
  if (inputValue === '') return inputValue;
  if (Number.isNaN(+inputValue)) return '0.00';
  if (inputValue.length === 1) {
    return `0${inputValue}`;
  }
  if (inputValue === '') {
    return '0.00';
  }
  return inputValue;
};

export const convertDateForButton = (filter) => {
  if (!filter.createDate) {
    return i18n.t('dateRangeUpT');
  }
  const { Start, End } = filter.createDate;
  const start = new Date(Start * 1000);
  const end = new Date(End * 1000);
  const startDate = start.getDate();
  const startMonth = start.toLocaleString('default', { month: 'short' });
  const endDate = end.getDate();
  const endMonth = end.toLocaleString('default', { month: 'short' });
  return `${startDate} ${startMonth.toUpperCase()} - ${endDate} ${endMonth.toUpperCase()}`;
};

export const convertToAmericanFormatPrice = (value) => {
  if (!value) return '';
  const newVal = `${value}`.replaceAll(',', '');
  const dotIndex = newVal.indexOf('.');
  const array = newVal.split('.');
  const firstPart = array[0].split('');
  firstPart.reverse();
  const arr = firstPart.map((item, index) => {
    if (index % 3 === 0 && index !== 0) {
      return `${item},`;
    }
    return item;
  });
  const readySecond = array[1];
  if (readySecond) {
    return `${arr.reverse().join('')}.${readySecond}`;
  }
  if (dotIndex !== -1) {
    return `${arr.reverse().join('')}.`;
  }
  return `${arr.reverse().join('')}`;
};

export const getPeriodStartInSeconds = (period) => {
  const now = new Date();
  switch (period) {
    case 'day': {
      const dayStart = new Date(Date.now());
      dayStart.setHours(0, 0, 0, 0);
      return dayStart.getTime() / 1000;
    }
    case 'yesterday': {
      return now.getTime() / 1000 - SECONDS_IN_DAY;
    }
    case 'thisMonth': {
      return now.getTime() / 1000 - SECONDS_IN_DAY * 30;
    }
    case 'thisYear': {
      return now.getTime() / 1000 - SECONDS_IN_DAY * 364;
    }
    case 'thisWeek':
      return now.getTime() / 1000 - SECONDS_IN_DAY * 7;
    case 'lastWeek':
      return now.getTime() / 1000 - SECONDS_IN_DAY * 7 * 2;
    default:
      throw new Error(
        'Unexpected function result. Prop must be a string: "day", "thisWeek" or "lastWeek"',
      );
  }
};

const getRoleWeight = (role) => {
  switch (role) {
    case 'Viewer':
      return 0;
    case 'viewer':
      return 0;
    case 'admin':
      return 3;
    case 'co_admin':
      return 2;
    case 'contributor':
      return 1;
    default:
      return 100;
  }
};
const getStatusWeight = (status) => {
  switch (status) {
    case 'Invited':
      return 0;
    case 'Active':
      return 1;
    case 'On Hold':
      return 2;
    case 'Blocked':
      return 3;
    case 'Deleted':
      return 4;
    default:
      return 100;
  }
};

export const sortTitleMapping = {
  nameAtoZ: 'Name A to Z',
  nameZtoA: 'Name Z to A',
  lastModified: 'Last Modified',
  firstModified: 'First Modified',
  lastAdded: 'Last Added',
  firstAdded: 'First Added',
  typeAtoZ: 'Type A to Z',
  typeZtoA: 'Type Z to A',
  sizeAtoZ: 'Size A to Z',
  sizeZtoA: 'Size Z to A',
  emailAtoZ: 'Email A to Z',
  emailZtoA: 'Email Z to A',
  roleAtoZ: 'Role A to Z',
  roleZtoA: 'Role Z to A',
  statusAtoZ: 'Status A to Z',
  statusZtoA: 'Status Z to A',
  titleAtoZ: 'Title A to Z',
  titleZtoA: 'Title Z to A',
  authorAtoZ: 'Author A to Z',
  authorZtoA: 'Author Z to A',
};

export const sortedFunctions = {
  nameAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 0
    const titleA = (a.title || a.name || '').toUpperCase();
    const titleB = (b.title || b.name || '').toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  }),
  nameZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 1
    const titleA = (a.title || a.name || '').toUpperCase();
    const titleB = (b.title || b.name || '').toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (titleA > titleB) {
      return -1;
    }
    if (titleA < titleB) {
      return 1;
    }
    return 0;
  }),
  lastModified: (arrayPages) => arrayPages.sort((a, b) => {
    // 2
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    return b.lastModifiedDate - a.lastModifiedDate;
  }),
  firstModified: (arrayPages) => arrayPages.sort((a, b) => {
    // 3
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    return a.lastModifiedDate - b.lastModifiedDate;
  }),
  lastAdded: (arrayPages) => arrayPages.sort((a, b) => {
    // 4
    if (a.isUpload) {
      return -1;
    }
    if (b.isUpload) return 1;
    return b.createDate - a.createDate;
  }),
  firstAdded: (arrayPages) => arrayPages.sort((a, b) => {
    // 5
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    return a.createDate - b.createDate;
  }),
  typeAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 6
    const typeA = a.type;
    const typeB = b.type;
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (typeA < typeB) {
      return -1;
    }
    if (typeA > typeB) {
      return 1;
    }
    return 0;
  }),
  typeZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 7
    const typeA = a.type;
    const typeB = b.type;
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (typeA > typeB) {
      return -1;
    }
    if (typeA < typeB) {
      return 1;
    }
    return 0;
  }),
  sizeAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 8
    const sizeA = a.size || 0;
    const sizeB = b.size || 0;
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (sizeA < sizeB) {
      return -1;
    }
    if (sizeA > sizeB) {
      return 1;
    }
    return 0;
  }),
  sizeZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 9
    const sizeA = a.size || 0;
    const sizeB = b.size || 0;
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (sizeA > sizeB) {
      return -1;
    }
    if (sizeA < sizeB) {
      return 1;
    }
    return 0;
  }),
  emailAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 10
    const emailA = a.email.toUpperCase();
    const emailB = b.email.toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (emailA < emailB) {
      return -1;
    }
    if (emailA > emailB) {
      return 1;
    }
    return 0;
  }),
  emailZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 11
    const emailA = a.email.toUpperCase();
    const emailB = b.email.toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (emailA > emailB) {
      return -1;
    }
    if (emailA < emailB) {
      return 1;
    }
    return 0;
  }),
  roleAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 12
    const roleA = getRoleWeight(a.role);
    const roleB = getRoleWeight(b.role);
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (roleA < roleB) {
      return -1;
    }
    if (roleA > roleB) {
      return 1;
    }
    return 0;
  }),
  roleZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 13
    const roleA = getRoleWeight(a.role);
    const roleB = getRoleWeight(b.role);
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (roleA > roleB) {
      return -1;
    }
    if (roleA < roleB) {
      return 1;
    }
    return 0;
  }),
  statusAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 14
    const statusA = getStatusWeight(a.status);
    const statusB = getStatusWeight(b.status);
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (statusA < statusB) {
      return -1;
    }
    if (statusA > statusB) {
      return 1;
    }
    return 0;
  }),
  statusZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 15
    const statusA = getStatusWeight(a.status);
    const statusB = getStatusWeight(b.status);
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (statusA > statusB) {
      return -1;
    }
    if (statusA < statusB) {
      return 1;
    }
    return 0;
  }),
  titleAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 16
    const titleA = (a.title || '').toUpperCase();
    const titleB = (b.title || '').toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  }),
  titleZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 17
    const titleA = (a.title || '').toUpperCase();
    const titleB = (b.title || '').toUpperCase();
    if (a.isUpload) return -1;
    if (b.isUpload) return 1;
    if (titleA > titleB) {
      return -1;
    }
    if (titleA < titleB) {
      return 1;
    }
    return 0;
  }),
  authorAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 18
    const ownerAName = (
      `${a?.owner?.first_name} ${a?.owner?.last_name}` || ''
    ).toUpperCase();
    const ownerBName = (
      `${b?.owner?.first_name} ${b?.owner?.last_name}` || ''
    ).toUpperCase();
    if (ownerAName < ownerBName) {
      return -1;
    }
    if (ownerAName > ownerBName) {
      return 1;
    }
    return 0;
  }),
  authorZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 19
    const ownerAName = (
      `${a?.owner?.first_name} ${a?.owner?.last_name}` || ''
    ).toUpperCase();
    const ownerBName = (
      `${b?.owner?.first_name} ${b?.owner?.last_name}` || ''
    ).toUpperCase();
    if (ownerAName > ownerBName) {
      return -1;
    }
    if (ownerAName < ownerBName) {
      return 1;
    }
    return 0;
  }),
  authorNameAtoZ: (arrayPages) => arrayPages.sort((a, b) => {
    // 20
    const ownerAName = (a.authorName || '').toUpperCase();
    const ownerBName = (b.authorName || '').toUpperCase();
    if (ownerAName < ownerBName) {
      return -1;
    }
    if (ownerAName > ownerBName) {
      return 1;
    }
    return 0;
  }),
  authorNameZtoA: (arrayPages) => arrayPages.sort((a, b) => {
    // 21
    const ownerAName = (a.authorName || '').toUpperCase();
    const ownerBName = (b.authorName || '').toUpperCase();
    if (ownerAName > ownerBName) {
      return -1;
    }
    if (ownerAName < ownerBName) {
      return 1;
    }
    return 0;
  }),
  shortcutsSmallToBig: (arrayPages) => arrayPages.sort((a, b) => {
    // 22
    const sequenceLengthA = a.sequences.length;
    const sequenceLengthB = b.sequences.length;
    if (sequenceLengthA < sequenceLengthB) {
      return -1;
    }
    if (sequenceLengthA > sequenceLengthB) {
      return 1;
    }
    return 0;
  }),
  shortcutsBigToSmall: (arrayPages) => arrayPages.sort((a, b) => {
    // 23
    const sequenceLengthA = a.sequences.length;
    const sequenceLengthB = b.sequences.length;
    if (sequenceLengthA > sequenceLengthB) {
      return -1;
    }
    if (sequenceLengthA < sequenceLengthB) {
      return 1;
    }
    return 0;
  }),
};

export function timeSince(date, isRemaining = false) {
  if (isNaN(date)) return i18n.t('Recently');
  const seconds = Math.abs(Math.floor((new Date() - date) / 1000));
  const text = isRemaining ? 'left' : 'ago';
  let interval = seconds / 31536000;
  if (interval > 1) {
    return i18n.t(`years${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return i18n.t(`months${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 86400;

  if (!isRemaining) {
    const today = new Date();
    const newDate = new Date(date);
    if (today.getFullYear() === newDate.getFullYear()
      && today.getMonth() === newDate.getMonth()
      && today.getDate() === newDate.getDate()) {
      return i18n.t('todayLowT');
    }
  }


  if (!isRemaining || (isRemaining && interval > 1)) {
    return i18n.t(`days${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 3600;
  if (interval > 1 && isRemaining) {
    return i18n.t(`hours${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  // interval = seconds / 60;
  // if (interval > 1) {
  //   return `${Math.floor(interval)} minute${Math.floor(interval) > 1 ? 's' : ''}`;
  // }
  return i18n.t('todayLowT');
}

export const createTimeRange = (start, end) => {
  const lang = i18n.language;
  const now = new Date();
  const endDate = new Date(end * 1000);
  const startDate = new Date(start * 1000);

  if (start && end) {
    const startDay = startDate.toLocaleDateString(lang, { day: 'numeric' });
    const startMonth = startDate.toLocaleDateString(lang, { month: 'short' });
    const startYear = startDate.toLocaleDateString(lang, { year: 'numeric' });

    const endDay = endDate.toLocaleDateString(lang, { day: 'numeric' });
    const endMonth = endDate.toLocaleDateString(lang, { month: 'short' });
    const endYear = endDate.toLocaleDateString(lang, { year: 'numeric' });
    if (now > startDate && now > endDate) {
      return i18n.t('unavailableT');
    }
    if (
      endMonth === startMonth
      && endYear === startYear
      && startDay === endDay
    ) {
      return `${i18n.t('chooseFromT')} ${startMonth} ${startDay}, ${startYear} `;
    }

    if (endMonth === startMonth && endYear === startYear) {
      return `${startMonth}, ${startDay} ${i18n.t('toT')} ${endDay}, ${startYear} `;
    }

    if (endYear === startYear) {
      return `${startMonth} ${startDay} ${i18n.t('toT')}  ${endDay} ${endMonth}, ${startYear} `;
    }
    return `${startMonth} ${startDay}, ${startYear} ${i18n.t('toT')} ${endDay} ${endMonth}, ${endYear}`;
  }
  if (start) {
    if (now > startDate) {
      return i18n.t('unavailableT');
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return `${i18n.t('chooseFromT')} ${startDate.toLocaleDateString(lang, options)}`;
  }

  if (end) {
    if (now > endDate) {
      return i18n.t('unavailableT');
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return `${i18n.t('upToT')} ${endDate.toLocaleDateString(lang, options)}`;
  }

  return i18n.t('openToReadT');
};

const sec = 'sec';
const min = 'min';
const hour = 'hour';
const day = 'day';
const month = 'month';
const year = 'year';

const valuesByKey = {
  [year]: SECONDS_IN_YEAR,
  [month]: SECONDS_IN_MONTH,
  [day]: SECONDS_IN_DAY,
  [hour]: SECONDS_IN_HOUR,
  [min]: SECS_IN_MINUTES,
  [sec]: 1,
};

const pipeTimeFunctions = (...fooAndKeyObj) => (time) => {
  let tmpTime = time;
  return fooAndKeyObj.reduce((acc, { foo, key }) => {
    acc[key] = foo(tmpTime);
    tmpTime -= acc[key] * valuesByKey[key];
    return acc;
  }, {});
};

const getPeriod = (time, key) => Math.floor(time / valuesByKey[key]);

const getSecs = (time) => getPeriod(time, sec);
const getMins = (time) => getPeriod(time, min);
const getHours = (time) => getPeriod(time, hour);
const getDays = (time) => getPeriod(time, day);
const getMonths = (time) => getPeriod(time, month);
const getYears = (time) => getPeriod(time, year);

const secs = { foo: getSecs, key: sec };
const mins = { foo: getMins, key: min };
const hours = { foo: getHours, key: hour };
const days = { foo: getDays, key: day };
const months = { foo: getMonths, key: month };
const years = { foo: getYears, key: year };

export const parseTimeWithPipe = (time) => pipeTimeFunctions(hours, mins, secs)(time);
export const parseTime = (time) => pipeTimeFunctions(years, months, days, hours, mins, secs)(time);

const castValue = (period, value) => (value > 0 ? ` ${value} ${pluralize(period, value)} ` : '');

export const getPlaylistBuilderItemDurationTime = (time) => {
  if (!time) return '0:10';
  const timeObject = parseTimeWithPipe(time, true);
  const timeResult = {};
  const infillObject = (key) => (value) => {
    timeResult[key] = timeResult[key]
      ? value + timeResult[key]
      : value.toString();
  };

  applyNewState(timeObject, 'hour', infillObject('hour'), infillObject('hour'));
  applyNewState(timeObject, 'min', infillObject('min'), infillObject('min'));
  applyNewState(timeObject, 'sec', infillObject('sec'), infillObject('sec'));

  return `${timeResult.hour === '00' ? '' : `${timeResult.hour}:`}${
    timeResult.min
  }:${timeResult.sec}`;
};

export const getReadableTimeLeft = (time, isShort) => {
  if (!time || typeof time !== 'number') return null;

  const myRangeSeconds = time - Math.floor(Date.now() / 1000);
  if (myRangeSeconds < 0) return 'Expired';
  const timeObj = parseTime(myRangeSeconds);

  if (timeObj.year) {
    return isShort
      ? castValue('year', timeObj.year)
      : castValue('year', timeObj.year)
          + castValue('month', timeObj.month)
          + castValue('day', timeObj.day);
  }
  if (timeObj.month) {
    return isShort
      ? castValue('month', timeObj.month)
      : castValue('month', timeObj.month) + castValue('day', timeObj.day);
  }
  if (timeObj.day) {
    return isShort
      ? castValue('day', timeObj.day)
      : castValue('day', timeObj.day) + castValue('hour', timeObj.hour);
  }
  if (timeObj.hour) {
    return isShort
      ? castValue('hour', timeObj.hour)
      : castValue('hour', timeObj.hour) + castValue('min', timeObj.min);
  }
  if (timeObj.min) {
    return isShort
      ? castValue('min', timeObj.min)
      : castValue('min', timeObj.min) + castValue('sec', timeObj.sec);
  }
  return isShort ? '< 1 min' : 'less then a minute';
};

export const isNotStart = (item) => {
  return item.availableFrom && item.availableFrom > Date.now() / 1000;
};

export const isEnd = (item) => item.availableTo && item.availableTo < Date.now() / 1000;

export const isPlaylistAvailableOnBoard = (item) => {
  if (!item.availableTo && !item.availableFrom) {
    return true;
  }
  if (item.availableFrom && item.availableFrom > Date.now() / 1000) {
    return false;
  }
  if (item.availableTo && item.availableTo < Date.now() / 1000) {
    return false;
  }
  return true;
};
export const isSharesPlaylistAvailableOnBoard = (item) => {
  if (!item.sharedAvailableTo && !item.sharedAvailableFrom) {
    return true;
  }
  if (
    item.sharedAvailableFrom
    && item.sharedAvailableFrom > Date.now() / 1000
  ) {
    return false;
  }
  if (item.sharedAvailableTo && item.sharedAvailableTo < Date.now() / 1000) {
    return false;
  }
  return true;
};
export const isItemAvailable = (item) => !isNotStart(item) && !isEnd(item);
export const isItemUnavailable = (item) => !isItemAvailable(item);

const SECONDS_IN_5_DAYS = 432_000;

export const filterRecentItem = (item) => {
  const now = Math.round(new Date().getTime() / 1000);
  return now - item.lastModifiedDate < SECONDS_IN_5_DAYS;
};
const convertToDouble = (num) => {
  if (num < 10) return `0${num}`;
  return num;
};

export const universalPlaylistDuration = (number, isTotal) => {
  if (!number || typeof number !== 'number') {
    return '0 min';
  }
  let tmpTime = number;
  const timeObj = {};
  timeObj.hour = Math.floor(tmpTime / SECONDS_IN_HOUR);
  tmpTime %= SECONDS_IN_HOUR;
  timeObj.min = Math.floor(tmpTime / MINS_IN_HOUR);
  tmpTime %= MINS_IN_HOUR;
  timeObj.sec = Math.floor(tmpTime);

  const result = [];

  if (isTotal) {
    if (timeObj?.hour) result.push(`${timeObj?.hour} h `);
    if (timeObj?.min) result.push(`${timeObj?.min} min `);
    if (timeObj?.sec) result.push(`${timeObj?.sec} sec `);
  } else if (timeObj?.hour) {
    result.push(`${timeObj?.hour}:`);
    result.push(`${convertToDouble(timeObj?.min) || '00'}:`);
    result.push(`${convertToDouble(timeObj?.sec) || '00'}`);
  } else {
    result.push(`${timeObj?.min || '0'}:`);
    result.push(`${convertToDouble(timeObj?.sec) || '00'}`);
  }

  return result.join('');
};
