import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import ReactDOM from 'react-dom';
import rawStyles from '../MakerCardView/index.module.scss';
import { ReactComponent as Plus16Svg } from '../../../images/icons/plus_16.svg';
import Empty from './Empty';
import { isRoleInPlaylist } from '../../../utils/permissions';
import MakerItem from './MakerItem';
import PlaceHolderAll from '../MakerCommon/Table/PlaceHolderAll';
import { actionRemoveAllSelected } from '../../../redux/selectedPage/action';
import { actionSelectItemInSmartfile } from '../../../redux/currentPage/action';
import useSelectAreaItems from '../../../utils/hooks/useSelectAreaItems';

const appRoot = document.getElementById('root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const styles = classNames.bind(rawStyles);

const AddElement = ({ hasDraggable, hasItems, isExpanded }) => {
  return (
    <div className={styles('create_new_element',
      { hoverable: hasDraggable, card_content: hasItems && !isExpanded, expanded_content: hasItems })}
    >
      <div className={styles('icon_wrapper')}>
        <Plus16Svg />
      </div>
    </div>
  );
};

const { isViewer } = isRoleInPlaylist;

const PlaylistsBuilderUIEx = ({
  isDownload,
  rawPages,
  setSelectedIndex,
  activeItemIndex,
  isPages,
  onEditorAreaMouseDrop,
  renderSelectionBox,
  hasDraggable,
  linkPages,
  onDragEnter,
  onDragLeave,
  createLinkPage,
  backSpaceHandler,
  indexHover,
  needToShowVioletStripe,
  refForScroll,
  ...props
}) => {
  const { selectedItems } = useSelector(state => state.currentPage);
  const makerAreaRef = useRef(null);

  const [onMouseDown, onMouseMove] = useSelectAreaItems(
    selectedItems,
    actionRemoveAllSelected,
    makerAreaRef,
    actionSelectItemInSmartfile,
    null,
    null,
    null,
    null,
    refForScroll,
  );


  if (isDownload) {
    return <></>;
  }

  return (
    <div
      tabIndex="0"
      className={styles('wrapper')}
      onKeyDown={backSpaceHandler}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
    >
      <div className={styles('builder__wrapper', { full_width: !isPages })}>
        <div
          className={styles('pages__section', { full_width: !isPages })}
        >
          {!isViewer[props.currentRole] && linkPages
            && linkPages.length === 0 && (
            <div
              className={styles('all_items_wrapper')}
              onDrop={(e) => {
                onEditorAreaMouseDrop(e, null, 'last');
              }}
              onDragEnter={(e) => {
                e.stopPropagation();
                onDragEnter('last');
              }}
              onDragLeave={onDragLeave}
            >
              <div className={styles('add_page_wrapper')}>
                <div
                  className={styles(
                    'next_item_container',
                    'no_elements',
                    'collapsed',
                  )}
                >
                  <div
                    className={styles('next_item_branch', 'hide', 'collapsed')}
                  />
                  {indexHover === 'first' && (
                    <div className={styles('dragLine_line', 'first')} />
                  )}
                </div>

                <div
                  className={styles('add_page', {
                    drop_active: !!hasDraggable,
                  })}
                >
                  <PlaceHolderAll create={createLinkPage} isForEmpty />
                </div>
              </div>
            </div>
          )}
          {!linkPages.length && (
            <Empty
              upvCard
              submitForm={props.submitForm}
              indexHover={indexHover}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onEditorAreaMouseDrop={onEditorAreaMouseDrop}
            />
          )}
          {linkPages && !!linkPages.length && (
            <div className={styles('pages_wrapper')}>
              <div className={styles('all_items_wrapper')} ref={makerAreaRef}>
                {rawPages.map((item, itemIndex) => (
                  <MakerItem
                    key={item.id}
                    itemIndex={itemIndex}
                    item={item}
                    rawPages={rawPages}
                    setSelectedIndex={setSelectedIndex}
                    isSelectLikeHover={activeItemIndex === itemIndex}
                    isHoverByDnd={indexHover === itemIndex}
                    isHoverByDndOnEmpty={indexHover === `${itemIndex}empty`}
                    onEditorAreaMouseDrop={onEditorAreaMouseDrop}
                    renderSelectionBox={renderSelectionBox}
                    hasDraggable={hasDraggable}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    createLinkPage={createLinkPage}
                    needToShowVioletStripe={needToShowVioletStripe}
                    {...props}
                  />
                ))}

                {!isViewer[props.currentRole] && (
                <div
                  className={styles('dragLine_wrap', { hasHoverDrag: indexHover === 'last' })}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    onEditorAreaMouseDrop(e, null, 'last');
                  }}
                  onDragEnter={(e) => {
                    e.stopPropagation();
                    onDragEnter('last');
                  }}
                  onDragLeave={onDragLeave}
                >
                  {indexHover === 'last' && (
                    <div className={styles('dragLine_line', 'last')} />
                  )}
                  <div onClick={() => createLinkPage(rawPages.length - 1)}>
                    <AddElement isExpanded={props.isExpanded} hasItems />
                  </div>
                </div>
                )}
                {isViewer[props.currentRole] && <div className={styles(rawStyles.viewer_empty_place)} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaylistsBuilderUIEx;
