import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash_Icon.svg';

import Tooltip from '../../../shared/Tooltips/Tooltip';
import { DATA_PARENTS } from '../../../utils/constants';

const DeleteButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 0.6px solid #ece2e2;
  border-radius: 32px;
  opacity: 0.5;
  padding-left: 7px;
  padding-top: 5px;
  ${({ isPageAndCompress }) => (isPageAndCompress ? `
     position: absolute;
     left: 48px;
     top: 0px;` : '')}
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const DeleteButtonShell2 = styled.div`
    right: 0;
  
    `;
const DeleteButtonShell = styled.div`
  position: absolute;
  align-items: flex-end;
  //flex-direction: column;
  justify-content: center;
  display: none;
  gap: 14px;
`;

const DeleteButtonBalancer = styled.div`
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  right: -40px;
  position: absolute;
  z-index: 100;
`;

const cx = classNames.bind(rawStyles);
const indent = 10;

const ElementHeightInCompress = 25;
const ElementHeightOther = 15;
const BottomIndentOther = 35;
const BottomIndentInCompress = 25;
const DefaultPosition = 10;

const DeleteContentButton = ({ deleteHandler, children, isCompressView, isShell, isPage, isText, refHoverForPositionAction }) => {
  const { t } = useTranslation();
  const ElementHeight = isCompressView ? ElementHeightInCompress : ElementHeightOther;
  const BottomIndent = isCompressView ? BottomIndentInCompress : BottomIndentOther;
  const [position, setPosition] = useState(0);

  const handleMouseMove = (event) => {
    const elementB = refHoverForPositionAction?.current;
    if (elementB) {
      const rect = elementB.getBoundingClientRect();

      if (isCompressView) {
        const y = event.clientY - rect.top;
        if (y < ElementHeight + indent + 2) { //  stop by top
          setPosition(DefaultPosition + 14);
        } else if (y > (rect.height - ElementHeight - indent)) { //  stop by bottom
          setPosition(rect.height - BottomIndent - 14);
        } else {
          setPosition(y - 15);
        }
      } else {
        const y = event.clientY - rect.top;
        if (y < ElementHeight + indent) { //  stop by top
          setPosition(indent);
        } else if (y > (rect.height - ElementHeight - 10)) { //  stop by bottom
          setPosition(rect.height - BottomIndent);
        } else {
          setPosition(y - 30);
        }
      }
    }
  };

  useEffect(() => {
    setPosition(0);
    const elementB = refHoverForPositionAction?.current;
    if (elementB) {
      elementB.addEventListener('mousemove', handleMouseMove);
      elementB.addEventListener('mouseleave', () => {
        const rect = elementB.getBoundingClientRect();
        if (event.clientY < rect.top) {
          setPosition(DefaultPosition);
        } else if (event.clientY > rect.bottom) {
          setPosition(rect.height - BottomIndent);
        }
      });
    }

    return () => {
      if (elementB) {
        elementB.removeEventListener('mousemove', handleMouseMove);
        elementB.removeEventListener('mouseleave', handleMouseMove);
      }
    };
  }, []);
  return (
    <DeleteButtonBalancer style={{ top: position }} isCompressView={isCompressView}>
      <DeleteButtonShell2>
        <DeleteButtonShell
          className={cx('rising_button_flex', 'rising_button')}
          data-parent={DATA_PARENTS.checkItem}
        >

          <DeleteButtonContainer
            isPageAndCompress={isPage && isCompressView}
            onClick={deleteHandler}
            data-parent={DATA_PARENTS.checkItem}
          >
            <Tooltip
              text={t('removeT')}
              direction="down"
              place="ActionsTrash"
              data-parent={DATA_PARENTS.checkItem}
            >
              <TrashIcon />
            </Tooltip>
          </DeleteButtonContainer>
          {children}

        </DeleteButtonShell>
      </DeleteButtonShell2>
    </DeleteButtonBalancer>
  );
};

export default DeleteContentButton;
