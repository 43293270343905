import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawElemStyles from './elements.module.scss';
import { ReactComponent as SixDotsSvg } from '../../../images/icons/six_dots.svg';
import { ReactComponent as None } from '../../../images/icons/icon-colour-none.svg';
import { ReactComponent as Add16Svg } from '../../../images/icons/plus_16.svg';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { NameAndImageAva } from '../MakerCommon/Table/NameAndImage';
import { calcCoEditorColor, calcCoEditorItemDate, getItemName } from '../../../utils/helpers';
import { AvaWrapper, UserAvatarWrapper } from '../styled';
import { BatchActionsPopup } from '../BatchActionsPopup';
import { actionRemoveLinkPageFromPlaylist } from '../../../redux/currentPage/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../redux/currentPage/types';
import { axiosAbortarium } from '../../../utils/axiosAbortarium';

import Playlist from '../../../redux/playlists/types';
import { actionShowMessage } from '../../../redux/support/action';
import { empty, Group, MessageType, openModalType } from '../../../utils/constants';
import { LibraryComponents } from '../../../redux/library/types';
import CheckBoxLinkPage from '../../../entities/CheckBoxLinkPage';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../../redux/user/action';
import i18n from '../../../i18n';

const cx = classNames.bind(rawElemStyles);

const SixDotsAnalogWrap = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  background: #ffc72c;
  left: -15px;
  top: 50%;
  z-index: 31;
  transform: translate(0%, -50%);
`;

const AddLine = styled.div`
    position: absolute;
    background: #FFC72C;
    color: #FFC72C;
    width: 930px;
    left: 5px;
    height: 2px;
    ${({ position }) => (`top:${position}px`)}
`;

// const BottomIndent = 55;
// const ElementHeight = 30;
const indent = 10;


const ElementHeightDefult = 24;
const ElementHeightCompress = 24;
const BottomIndentDefult = 65;
const BottomIndentCompress = 53;

const SixDotsItem = ({
  menuRef,
  isActive,
  setIsActive,
  itemStylesObject,
  otherOwner,
  duplicateHandler,
  currentRole,
  refHoverForPositionAction,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { singleUserShareState, selectedItems } = useSelector(state => state.currentPage);
  const { isViewer: isViewerEx, isCoEdit: isCoEditEx } = isRoleInPlaylist;
  const { viewMode } = useSelector((state) => state.upv);

  const isViewer = isViewerEx[currentRole];
  const isCoEdit = isCoEditEx[currentRole];
  const isTitle = itemStylesObject?.isTitle;
  const isDisabled = (isViewer || isCoEdit && otherOwner) && props.item.isRemixLocked;
  const { manager, id } = useSelector((state) => state.currentPage);
  const user = useSelector((state) => state.user || empty);
  const clickHandler = (e) => {
    e && e.stopPropagation();
    if (isViewer) return;
    props.clear();
    setIsActive(!isActive);
  };
  const buttonRef = useRef(null);
  const calcDate = useMemo(() => {
    return calcCoEditorItemDate(props.item.lastModifiedDate ?? props.item.createDate);
  }, [props.item.createDate, props.item.lastModifiedDate]);

  const itemColor = useMemo(() => {
    return calcCoEditorColor(singleUserShareState, props.ownerID, props.item.owner?.id);
  }, [singleUserShareState, props.item.owner.id]);

  const isOnlyAvatarsDisplay = window.innerWidth < 1850;
  const ElementHeight = props.isCompressView ? ElementHeightCompress : ElementHeightDefult;
  const BottomIndent = props.isCompressView ? BottomIndentCompress : BottomIndentDefult;
  const [position, setPosition] = useState(0);
  const [isTop, setIsTop] = useState(false);
  const [isLineVisible, setIsLineVisible] = useState(false);

  const handleMouseMove = (event) => {
    if (isActive) return;

    const elementB = refHoverForPositionAction?.current;
    if (elementB) {
      const rect = elementB.getBoundingClientRect();
      if (props.isCompressView) {
        const y = event.clientY - rect.top;
        if (y < ElementHeight + indent) { //  stop by top
          setPosition(indent);
          setIsTop(true);
        } else if (y > (rect.height - ElementHeight - indent)) { //  stop by bottom
          setIsTop(false);
          setPosition(rect.height - BottomIndent);
        } else {
          setIsTop(y < rect.height / 2);
          setPosition(y - ElementHeight);
        }
      } else {
        const y = event.clientY - rect.top;
        if (y < ElementHeight + indent) { //  stop by top
          setPosition(indent);
          setIsTop(true);
        } else if (y > (rect.height - ElementHeight - 10)) { //  stop by bottom
          setIsTop(false);
          setPosition(rect.height - BottomIndent);
        } else {
          setIsTop(y < rect.height / 2);
          setPosition(y - 30);
        }
      }
    }
  };

  useEffect(() => {
    const elementB = refHoverForPositionAction?.current;
    const handlemouseLeave = () => {
      const rect = elementB.getBoundingClientRect();
      // setPosition((rect.height / 2) - 25);
      if (isActive) return;
      if (event.clientY < rect.top) {
        setPosition(0);
      } else if (event.clientY > rect.bottom) {
        setPosition(rect.height - BottomIndent);
      }
    };
    if (elementB) {
      elementB.addEventListener('mousemove', handleMouseMove);
      elementB.addEventListener('mouseleave', handlemouseLeave);
    }

    return () => {
      if (elementB) {
        elementB.removeEventListener('mousemove', handleMouseMove);
        elementB.removeEventListener('mouseleave', handlemouseLeave);
      }
    };
  }, [isActive]);

  useEffect(() => {
    setPosition(0);
  }, [viewMode]);

  const addLinePositionDef = isTop ? -15 : refHoverForPositionAction?.current?.getBoundingClientRect().height - 15;
  const addLinePosition = props.isCompressView ? addLinePositionDef + 5 : addLinePositionDef;

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setShow) {
      props.setShow(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  const summarizeHandler = () => {
    if (isTitle) {
      dispatch(actionShowMessage({
        type: MessageType.NeutralRegular,
        text: i18n.t('ActionNotSupportedT'),
      }));
    } else {
      dispatch(
        actionCreator(CurrentPage.Summarize, {
          linkPages: [props.item.id],
        }),
      );
    }
  };

  const translateHandler = () => {
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { type: 'translate', element: props.item }),
    );
  };

  const onLockButtonClickHandler = (e) => {
    e.stopPropagation();
    if (props.isDisabled) {
      return;
    }

    dispatch(
      actionCreator(CurrentPage.UpdateRemixState, {
        linkPageId: props.item.id,
        playlistId: id,
        value: !props.item.isRemixLocked,
      }),
    );
  };

  const isOwner = user.id === props.item?.owner?.id;
  const isDownloadDisabled = !isOwner && props.item.isRemixLocked;
  const onDownloadButtonClickHandler = (e) => {
    e.stopPropagation();

    const libraryComponentId = props.item?.libraryComponent?.id
      || props.item?.textComponent?.id;
    if (props.item.isRemixLocked && isDownloadDisabled) {
      return;
    }
    axiosAbortarium.generateNew(props.item.id);
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );

    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: props.item?.title || props.item?.libraryComponent?.title,
        elementType: props.item?.libraryComponent?.type || props.item?.type,
        isText: !!props.item.textComponent?.id,
        text: getItemName(props.item),
        owner: props.item.owner || { id: user.id },
      }),
    );
  };

  const onAddItemsToLibraryClickHandler = (e) => {
    e.stopPropagation();
    if (
      props.item.type !== 'elementComponent'
      && props.item?.libraryComponent?.type !== 'page'
    ) return;
    dispatch(
      actionCreator(LibraryComponents.Player.DuplicateSharedComponent, {
        componentId: props.item?.libraryComponent?.id,
        linkPageId: props.item?.id,
        type: props.item?.libraryComponent?.type,
      }),
    );
  };
  const handleAddToSF = () => {
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { element: props.item, type: 'AddToSF' }),
    );
  };

  const SixDotsPortal = () => {
    const sixDotsBtnPos = buttonRef.current?.getBoundingClientRect();
    const { left, top } = sixDotsBtnPos;
    return (
      <BatchActionsPopup
        popupRef={menuRef}
        item={props.item}
        selectedItems={selectedItems}
        setClosed={setIsActive}
        downloadHandler={onDownloadButtonClickHandler}
        duplicateHandler={duplicateHandler}
        deleteHandler={deleteLinkPageClickHandler}
        lockHandler={onLockButtonClickHandler}
        summarizeHandler={summarizeHandler}
        translateHandler={translateHandler}
        addToSmartfileHandler={handleAddToSF}
        addToLibraryHandler={onAddItemsToLibraryClickHandler}
        isSixDots
        left={left}
        top={top}
      />
    );
  };

  return (
    <>
      {isLineVisible && (<AddLine position={addLinePosition} />)}
      <div
        className={cx('six_dot_wrap', {
          just_a_viewer: isViewer,
          on: isActive,
          // isCardView: !isExpandedView,
        })}
        style={{ top: position }}
      >
        <div
          className={cx('checkbox_balancer', {
            selected_checkbox: selectedItems[props.item.id],
            isPlaceHolder: props.isPlaceHolder,
          })}
        >
          <CheckBoxLinkPage
            itemId={props.item.id}
            isSelected={selectedItems[props.item.id]}
            isDisabled={isDisabled}
          />
        </div>
        <div className={cx('six_dot_positioning')}>
          {!isViewer && (
          <div
            data-cy="playlist-element-hover"
            ref={buttonRef}
            className={cx('six_dot_dropMenu_wrapper', { six_dot_dropMenu_wrapper_active: isActive })}
          >
            {isActive && (
            <div className={cx('batch_actions_wrapper')}>
              {createPortal(SixDotsPortal(), document.body)}
            </div>
            )}
            {!otherOwner && (
            <Tooltip
              text={t('sixDotsTooltipT')}
              direction="down"
              place="six_dots"
            >
              <UserAvatarWrapper
                color={itemColor}
                draggable={!otherOwner}
                onDragStart={props.dragStartHandler}
                onDragEnd={props.onDragEndHandler}
                className={cx(
                  { six_dot_icon: !otherOwner, otherOwner: !!otherOwner, on: isActive },
                )}
                onClick={clickHandler}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                <SixDotsSvg />
              </UserAvatarWrapper>
            </Tooltip>
            )}
          </div>
          )}
          {!manager?.userHideDateAvatar && (
          <AvaWrapper calcColor={itemColor} isOnlyAvatarsDisplay={isOnlyAvatarsDisplay}>
            {!isOnlyAvatarsDisplay && calcDate}
            <NameAndImageAva
              noStick
              avaURL={props.item.owner?.avatarUrlVerySmall}
              first_name={props.item.owner?.first_name}
              last_name={props.item.owner?.last_name}
              displayImage={props.item.owner.displayImage}
              upvCard
              itemColor={itemColor}
              isOnlyAvatarsDisplay={isOnlyAvatarsDisplay}
              calcDate={calcDate}
            />
          </AvaWrapper>
          )}
          {otherOwner && (
          <SixDotsAnalogWrap
            onClick={clickHandler}
            className={cx('show_analog_wrap_on_hover')}
          >
            <None />
          </SixDotsAnalogWrap>
          )}
          {!isTitle && (
          <div className={cx('six_dot_permanent_line')}>
            <div className={cx('line', `${itemStylesObject.style}`)} />
          </div>
          )}
          {!isViewer && (
          <div
            onMouseEnter={() => setIsLineVisible(true)}
            onMouseLeave={() => setIsLineVisible(false)}
            className={cx('six_dot_stripe')}
          >
            <Tooltip text={t(isTop ? 'plusTooltipAboveT' : 'plusTooltipBellowT')} direction="down" place="plus_item">
              <div
                onClick={(e) => props.createHandler(true, e, isTop)}
                className={cx('plus', { plus_viewer: isViewer })}
              >
                <Add16Svg />
              </div>
            </Tooltip>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SixDotsItem;
