import React, { useEffect, useMemo, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AvatarGroupComponent } from './AvatarGroup';
import {
  GreyItemsSplitter,
  ItemWrapperUPV,
  TextWrapperUPV,
} from './styled';
import { timeSince } from '../../../../utils/dateConvert';
import { universalPlaylistSize } from '../../../../utils/helpers';
import {
  AvatarInfoRowShell,
  MakerFlexable,
  MakerInfoRowShell,
  DownloadButton,
} from '../../styled';
import { actionGetUsersToSharingPl } from '../../../../redux/playlists/action';

import Tooltip from '../../../../shared/Tooltips/Tooltip';
import { SHARED_PLAYLIST_URL } from '../../../../utils/constants';
import { isRoleInPlaylist } from '../../../../utils/permissions';

import EndDate from './EndDate';
import PlaylistMainControls from '../../PlaylistMainControls';
import SwitchModeAndPublishBlock from '../../SwitchModeAndPublishBlock';

const MakerInfoRow = ({
  linkPages,
  lastModifiedDate,
  handleDownload,
  isCanManyDownload,
  cutMode,
  open,
  setOpen,
  lExpand,
  setViewMode,
  viewMode,
  isForSharedToWeb,
  refForScroll,
  isExpand,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isDownload,
    singleUserShareState,
    currentRole,
  } = useSelector(state => state.currentPage);
  const history = useHistory();

  const { isOwner } = isRoleInPlaylist;
  const coEditorsToDisplay = useMemo(() => {
    return Object.values(singleUserShareState).filter(it => it.isCoEdit);
  }, [singleUserShareState]);

  useEffect(() => {
    if (history.location.pathname.includes(`/${SHARED_PLAYLIST_URL}`)) return;

    dispatch(actionGetUsersToSharingPl({ type: 'playlist' }));
  }, [dispatch, history.location.pathname]);

  const elemsCount = linkPages?.filter(elem => elem.type !== 'elementText').length;

  const calcPlaylistSize = useMemo(() => {
    return universalPlaylistSize(linkPages, true);
  }, [linkPages]);
  return (
    <MakerInfoRowShell>
      <MakerFlexable>
        {!isDownload && (
          <>
            {!!coEditorsToDisplay.length && (
              <AvatarInfoRowShell>
                <AvatarGroupComponent users={coEditorsToDisplay} />
                <TextWrapperUPV>
                  {coEditorsToDisplay.length === 1 ? t('coEditorT') : `${coEditorsToDisplay.length} ${t('coEditorsT')}`}
                </TextWrapperUPV>
                <GreyItemsSplitter />
              </AvatarInfoRowShell>
            )}
            <ItemWrapperUPV>
              <TextWrapperUPV>{elemsCount || 0} {t('itemsLowT')}</TextWrapperUPV>
            </ItemWrapperUPV>

            <ItemWrapperUPV>
              <GreyItemsSplitter />
              <TextWrapperUPV>{calcPlaylistSize}</TextWrapperUPV>
            </ItemWrapperUPV>

            <ItemWrapperUPV>
              <GreyItemsSplitter />
              <TextWrapperUPV>{t('updatedT')} {timeSince((lastModifiedDate ?? 0) * 1000)}</TextWrapperUPV>
            </ItemWrapperUPV>

            {!isOwner[currentRole] && (
              <ItemWrapperUPV>
                <GreyItemsSplitter />
                <EndDate />
              </ItemWrapperUPV>
            )}
            <PlaylistMainControls
              cutMode={cutMode}
              open={open}
              setOpen={setOpen}
              smExpand
              lExpand={lExpand}
              setViewMode={setViewMode}
              viewMode={viewMode}
              currentRole={currentRole}
              isForSharedToWeb={isForSharedToWeb}
              refForScroll={refForScroll}
              isExpand={isExpand}
            />
            <SwitchModeAndPublishBlock
              isLargeRightBar={false}
              viewMode={viewMode}
              setViewMode={setViewMode}
              moveLeft={false}
              isShowLibraryWidget={false}
            />
          </>
        )}
      </MakerFlexable>
      {!isOwner[currentRole] && (
        <Tooltip
          text={t('downloadThisSmartFileT')}
          direction="down"
        >
          <DownloadButton
            onClick={handleDownload}
            isDisabled={!linkPages?.length || !isCanManyDownload}
          >
            {t('downloadUpT')}
          </DownloadButton>
        </Tooltip>
      )}

    </MakerInfoRowShell>
  );
};

export default MakerInfoRow;
