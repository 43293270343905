import React from 'react';
import styled from 'styled-components/macro';
import FolderIconLayout from '../../FolderIconLayout';

const UniversalPlaylistImageShell = styled.div`
  position: relative;
  ${({ newUPV }) => (newUPV
    ? `
    height: 100%;
  width: 100%;
  background: white;
  border-radius: 10px;

  `
    : `  
  margin-right: 28px;
  margin-bottom: 22px;
  height: 260px;`)}
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 10px;
  //box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.25);
  ${({ newUPV }) => (newUPV
    ? `    
      height: 170px;
      width: 170px;`
    : `  
      height: 170px;
      border: 1px solid #ECE2E2;
      width: 170px;`
  )}
`;

const OverlayWrapper = styled.div`
  svg {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 170px;
    height: 170px;
  }
`;
const UniversalPlaylistImageUI = ({ img, newUPV }) => {
  return (
    <UniversalPlaylistImageShell newUPV={newUPV} noImage={!img?.urlFile}>
      {!!img?.urlFile && <Image src={img?.urlFile} newUPV={newUPV} />}
      <OverlayWrapper>
        <FolderIconLayout isOverCoverImage />
      </OverlayWrapper>
    </UniversalPlaylistImageShell>
  );
};

export default UniversalPlaylistImageUI;
