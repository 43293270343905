import styled from 'styled-components/macro';

export const LeftNav = styled.div`
  display: flex;
  ${({ isMaker }) => (isMaker ? 'position: absolute;' : '')}
`;
//
export const Controls = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarPlaceHolder = styled.div`
 height: 1px;
 width: 140px;
`;

export const ActiveContent = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  &, & > p{
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  width: calc(100vw - 430px);
  justify-content: space-between;
`;

export const HeaderInfo = styled.div`
  display: flex;
`;

export const RoleDISPLAYOwner = styled.div`
  position: absolute;

  background-color: red;
`;
export const RoleDISPLAYCoedit = styled.div`
  background-color: blue;
  position: absolute;
`;
export const RoleDISPLAYView = styled.div`
  background-color: grey;
  position: absolute;
`;
export const ArrowWrapper = styled.div`
  margin-right: 17px;
  svg path {
    fill: #484343;
    fill-opacity: 1;
  }
  cursor: pointer;
  svg rect {
    fill: #484343;
    fill-opacity: 1;
  }

  ${({ isDisabled }) => (isDisabled
    ? `
  cursor:default;
  svg path {
 fill:rgba(52, 52, 52, 0.1);
}
  svg rect {
 fill:rgba(52, 52, 52, 0.1);
  }
  `
    : `
  `)}
  ${({ mT }) => (mT ? `margin-top:${mT}px;` : '')}
`;
export const PlayButton = styled.div`
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
  }

  ${({ isDisabled }) => (isDisabled
    ? `
    cursor: no-drop;
    svg circle{
      fill: lightgrey;
    }
    &:hover svg circle{
      fill: lightgrey;
    }
  `
    : '')}
    ${({ isMaker }) => (isMaker
    ? `
      margin-left: 8px;

      svg {
        position: relative;
        top: 1px;
        width: 38px;
        height: 38px;
      }
      svg circle{
        fill: #fff;
      }
      &:hover svg circle{
        fill: #FFEEBF;
        stroke-width: 0.6px;
        stroke: #FFC72C;
      }
    `
    : '')}
`;
export const SmallVerticalSeparator = styled.div`
  width: 1px;
  height: 30px;
  background: rgba(29, 29, 29, 0.08);
  margin-left: 2px;
  margin-right: 20px;
  position: relative;
  top: -4px;
  ${({ theSecond }) => (theSecond
    ? `
    height: 22px;
    margin-left: 0px;
    margin-right: 10px;
    top: 0;
  `
    : '')}
  ${({ theThird }) => (theThird
    ? `
    height: 22px;
    margin-left: 10px;
    margin-right: 0px;
    top: 6px;
  `
    : '')}
  ${({ mT }) => (mT ? `margin-top:${mT}px;` : '')}
`;

export const SmallVerticalSeparatorInCuteHeader = styled.div`
  width: 1px;
  height: 22px;
  background: rgba(29, 29, 29, 0.08);
  margin: 0 3px 0 10px;
`;
export const ViewModeSwitcherWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ forCutHeader }) => (forCutHeader
    ? `
      margin-top: 18px;
    `
    : 'position: absolute; right: 0;')}
  ${({ forCutHeader, moved }) => (moved && forCutHeader
    ? `
    right: 9.2%;
  `
    : '')}
`;
export const ViewOptionShell = styled.div`
  display: flex;
  gap: 4px;
`;
export const SvgWrap = styled.div`
  background: #fff;
  border-radius: 19px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #ffeebf;
  }
  ${({ isActive }) => (isActive ? `
    background: #FFEEBF;
    svg path {
      fill: var(--Brand_Scheme_bg_default);
    }
    ` : '')}

  &:active {
      background: #FFE9AD;
  }
`;
export const TOCSvgShell = styled.div`
  position: absolute;
  left: 27px;
  top: calc(50% + 33px);
  transform: translateY(-50%);
  z-index: 8;
  cursor: pointer;
`;

export const ViewModeWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ChevronWrapper = styled.div`
  width: 16px;
  height: 24px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  :hover {
    background: rgb(255, 238, 191);
  }
`;

export const Popup = styled.div`
  position: absolute;
  z-index: 101;
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}
  height: 84px;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.25);
  padding: 12px 0;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 0.6px solid #ECE2E2;
    border-left: 0.6px solid #ECE2E2;
    padding: 5px 16px;
    cursor: pointer;
    height: 30px;
    gap: 10px;

    p {
      margin: 0; 
    }
    
    :hover {
      background: #FFEEBF;
    }
  }

  svg path {
    stroke: #484343;
  }
`;
